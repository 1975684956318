import Dialog from '~/components/Dialog';
import Typography from '~/components/Typography';
import Container from "~/components/Container.tsx";
import {useTranslation} from "react-i18next";

export type PayOnDeliveryConfirmProps = {
  open: boolean,
  onConfirm: () => void,
  onClose: () => void
}

export default function PayOnDeliveryConfirm(props: PayOnDeliveryConfirmProps) {
  const {open, onConfirm, onClose} = props;
  const {i18n} = useTranslation();
  return (
    <Dialog
      title={
        i18n.t('shop-order-payOnDelivery', {time: '8PM ~ 10AM'}) // Pay on delivery between {time:9PM ~ 12AM}
      }
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <Container
        px={'20px'}
        stackProps={{
          gap: '8px'
        }}
      >
        <Typography color={'typography.secondary'}>
          {
            i18n.t('shop-order-deliveryTime', {time: '8 PM ~ 10 PM'}) // The delivery will take place between {time: 8 PM ~ 11 PM}
          }
          {
            i18n.t('shop-order-paymentUponDelivery') // and payment is made in person upon delivery.
          }
        </Typography>
        <Typography color={'typography.secondary'}>
          {
            i18n.t('shop-order-autoRefundPolicy') // If we cannot reach you or if delivery cannot be made within 15 minutes of arrival, the order may be automatically refunded.
          }
        </Typography>
        <Typography color={'typography.secondary'}>
          {
            i18n.t('shop-order-contactBeforeDelivery') // We will contact you before delivery.
          }
        </Typography>
        <Typography color={'typography.secondary'} fontWeight={'bold'}>
          {
            i18n.t('shop-order-checkContactInfo') // Please check your contact information and notification settings.
          }
        </Typography>
      </Container>
    </Dialog>
  )
}
