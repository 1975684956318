import {useLayoutEffect} from "react";
import router from '../router';
import useKintStore from "~kint/store";
import {pushLog} from "~kup/controllers/log.ts";

export default function useListenRouterPath() {
  const {reservation, participant, team} = useKintStore((state) => ({
    reservation: state.reservation,
    participant: state.participant,
    team: state.team
  }));

  useLayoutEffect(() => {
    return router.subscribe((state) => {
      const pathname = state.location?.pathname;
      const hash = state.location?.hash || undefined;
      const query = state.location?.search || undefined;
      const reservationId = reservation?.id;
      const reservationAgencyCode = reservation?.agencyCode;
      const reservationDate = reservation?.date;
      const participantId = participant?.id;
      const participantName = participant?.name;
      const tourId = reservation?.productId;
      const teamId = reservation?.teamId;
      const guideId = team?.guides?.map(g => g.id).join('/');
      const guideName = team?.guides?.map(g => g.name).join('/');

      pushLog(pathname, {
        hash,
        query,
        reservationId,
        reservationAgencyCode,
        reservationDate,
        participantId,
        participantName,
        tourId,
        teamId,
        guideId,
        guideName
      }).catch(console.error);
    });
  }, []);
}
