import api from '~kup/api';

const LOG_PATH = {
  LOG: () => ['metric', 'user-path'].join('/'),
};

export async function pushLog(
  pathname: string,
  optional: {
    hash?: string,
    query?: string,
    reservationId?: string,
    reservationAgencyCode?: string,
    reservationDate?: string,
    participantId?: string,
    participantName?: string,
    tourId?: string,
    teamId?: string,
    guideId?: string,
    guideName?: string
  }
): Promise<void> {
  await api.post(LOG_PATH.LOG(), {
    pathname,
    ...optional,
  });
}
