import {Link, useNavigate} from "react-router-dom";
import Page from '~/components/Page';
import Headline from "~/components/Headline.tsx";
import Container from "~/components/Container.tsx";
import TextField from "~/components/TextField.tsx";
import Typography from "~/components/Typography.tsx";
import Button from "~/components/Button.tsx";
import {useState} from "react";
import useSearchAddress from "~/pages/shop/address/hooks/useSearchAddress.ts";
import {useSnackBar} from "~/contexts/SnackBarContext.tsx";
import FillBox from "~/components/FillBox.tsx";
import {useTranslation} from "react-i18next";

export default function ShopAddressPage() {
  const navigate = useNavigate();
  const {i18n} = useTranslation();
  const [searchAddress, setSearchAddress] = useState<string>('');
  const {loading, fetchAddresses} = useSearchAddress();
  const {showSnackBar} = useSnackBar();

  const handleSearchAddress = async () => {
    const addresses = await fetchAddresses(searchAddress);
    if (addresses.length === 0) {
      showSnackBar({message: i18n.t('shop-address-unableToVerifyAddress') // Unable to verify the address
        , lift: true, action:<Button size={'small'} onClick={()=>navigate('/shop/address/search')}> Search </Button>});
      return;
    }
    navigate(
      '/shop/address/check',
      {state: {address: addresses[0], inputAddress: searchAddress}},
    );
  }

  return (
    <Page
      type={'page'}
      fixedBottom={
        <Container py={'8px'} px={'20px'}>
          <Button
            fullWidth
            onClick={handleSearchAddress}
            loading={loading}
          >
            {
              i18n.t('shop-address-next') // Next
            }
          </Button>
        </Container>
      }
    >
      <Container
        py={'8px'}
      >
        <Container
          pt={'16px'}
          pb={'20px'}
          px={'20px'}
        >
          <Headline headline={
            i18n.t('shop-address-whereToDeliver') // Where should we deliver it?
          } size={'large'}/>
        </Container>
        <Container
          p={'8px'}
          px={'20px'}
          stackProps={{
            gap: '16px',
            justifyContent: 'center',
          }}
        >
          <TextField
            fullWidth
            placeholder={
              i18n.t('shop-address-enterAddressHere') // Enter Address Here (Available in Seoul Only)
            }
            value={searchAddress}
            onChange={(e) => setSearchAddress(e.target.value)}
          />
          <FillBox
            color={'fill'}
            display={'flex'}
            flexDirection={'column'}

          >
            <Typography variant={'BaseM_B'} component={'p'}>
              {
                i18n.t('shop-address-example') // Example
              }
            </Typography>
            <Typography component={'p'}>
              110 Sejong-daero, Jung-gu, Seoul
            </Typography>
            <Typography component={'p'}>
              12, Supyo-ro
            </Typography>
            <Typography component={'p'} variant={'FootNote'} pt={'12px'} whiteSpace={'pre-wrap'}>
              {
                i18n.t('shop-address-poweredByGoogleMaps') // This is powered by Google Maps.\nYou can also find a valid address using Google Maps or the search function below.
              }
            </Typography>
            <Link
              color={'fill'}
              to={'/shop/address/search'}
              style={{
                marginTop:'4px'
              }}
            >
              <Typography
                sx={{
                  width: '100%',
                  display: 'flex',
                  textDecoration: 'underline'
                }}
                color={'text.secondary'}
                variant={'FootNote_B'}
              >
                {
                  i18n.t('shop-address-findAddressByHotelName') // Find address by hotel
                }
              </Typography>
            </Link>
          </FillBox>

        </Container>
      </Container>
    </Page>
  )
}
