import {Slice} from "~common/store";
import {ReservationSlice} from "./reservationSlice.ts";
import {Team} from "~kint/models/Team.ts";

export interface TourSlice {
  tourId: string | null,
  reservationOn: boolean,
  team: Team | null,
  setTeam: (team: Team | null) => void,
}

export interface PersistedTourSlice {
  tourId: string | null,
  team: Team | null
}

export const persistTourSlice: (state: TourSlice) => PersistedTourSlice = (state: TourSlice) => ({
  tourId: state.tourId,
  team: state.team,
})


export const createTourSlice: Slice<TourSlice & ReservationSlice, TourSlice> = (set, get) => ({
  tourId: null,
  team: null,
  setTeam: (team) => set({team}),
  reservationOn: !!get()?.reservation
})

