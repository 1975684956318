import {useState} from 'react';
import {useNavigate} from "react-router-dom";

import useKupStore from "~kup/store";
import {MESSENGERS} from "~/pages/shop/constants";

import Page from '~/components/Page';
import Headline from "~/components/Headline.tsx";
import Container from "~/components/Container.tsx";
import Select, {SelectProps} from "~/components/Select.tsx";
import Stack from "~/components/Stack.tsx";
import Typography from "~/components/Typography.tsx";
import TextField, {TextFieldProps} from "~/components/TextField.tsx";
import Button, {ButtonProps} from "~/components/Button.tsx";
import Icon from "~/components/Icon";
import {useTranslation} from "react-i18next";


export default function ShopContactPage() {
  const {contact, setContact} = useKupStore();
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const [channel, setChannel] = useState<string>(contact?.channel ?? '');
  const [identifier, setIdentifier] = useState<string>(contact?.identifier ?? '');

  const isIdBaseChannel = ['Line', 'WeChat'].includes(channel)
  const isAddressBaseChannel = ['Email'].includes(channel)
  const isOthers = ['Others'].includes(channel);
  const isTelChannel = !isIdBaseChannel && !isAddressBaseChannel && !isOthers


  const handleSelectMessenger: SelectProps['onChange'] = (e) => setChannel(e.target.value as string);
  const handleChangeMessengerId: TextFieldProps['onChange'] = (e) => setIdentifier(e.target.value);
  const handleNext: ButtonProps<'button'>['onClick'] = () => {
    if (channel && identifier) {
      setContact({
        channel,
        identifier
      });
      navigate(-1)
    }
  }


  return (
    <Page
      type={'page'}
      fixedBottom={
        <Container
          py={'8px'}
          px={'20px'}
        >
          <Button
            disabled={!channel || !identifier}
            fullWidth
            onClick={handleNext}
          >
            {
              i18n.t('shop-contact-confirm') // Confirm
            }
          </Button>
        </Container>
      }
    >
      <Container
        py={'8px'}
      >
        <Container
          py={'16px'}
          px={'20px'}
        >
          <Headline headline={
            i18n.t('shop-contact-howCanWeReachYou') // How can we reach you?
          }
                    description={
                      i18n.t('shop-contact-infoForUpdates') // We'll use this info to update you about your order and delivery.
                    }
                    size={'large'}
          />
        </Container>
        <Container
          py={'8px'}
          px={'20px'}
          stackProps={{
            column: true,
            gap: '8px'
          }}
        >
          <Select
            fullWidth
            value={channel}
            onChange={handleSelectMessenger}
            placeholder={
              i18n.t('shop-contact-selectMessenger') // Please Select Messenger
            }
            menus={MESSENGERS.map(({label, value, icon}) => ({
              label: <Stack row alignItems={'center'} gap={'8px'}>
                <Icon width={'16px'}>{icon}</Icon>
                <Typography variant={'BaseM_B'} color={'typography.primary'}>{label}</Typography>
              </Stack>,
              value,
            }))}
          />
          <Stack
            column
            gap={'4px'}
          >
            <TextField
              fullWidth
              value={identifier}
              onChange={handleChangeMessengerId}
              placeholder={
                isTelChannel
                  ? i18n.t('shop-contact-enterNumber') // Please enter the number
                  : isAddressBaseChannel
                    ? i18n.t('shop-contact-enterEmailAddress') // Please enter your email address
                    : isIdBaseChannel
                      ? i18n.t('shop-contact-enterID') // Please enter the ID
                      : i18n.t('shop-contact-enterPreferredMethod') // Please enter your preferred method

              }
            />
            <Typography variant={'caption'} color={'warning.main'}>
              {
                isTelChannel
                  ? i18n.t('shop-contact-enterCountryCode') // Please enter the +country code if it is your phone number.
                  : isAddressBaseChannel
                    ? i18n.t('shop-contact-enterNotificationEmail') // Please enter your email address where you can receive notification.
                    : isIdBaseChannel
                      ? i18n.t('shop-contact-enterCorrectID') // Please enter the correct ID.
                      : i18n.t('shop-contact-enterPreferredContactMethod') // Please enter the preferred method to contact you.
              }

            </Typography>
          </Stack>
        </Container>
      </Container>
    </Page>
  )
}
