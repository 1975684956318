import Dialog from "~/components/Dialog.tsx";
import useKupNotificationToken from "~/hooks/useKupNotificationToken.ts";
import Container from "~/components/Container.tsx";
import Typography from "~/components/Typography.tsx";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function KupNotificationPermissionDialog(props: {
  timeout?: number,
  open?: boolean,
  onClose?: () => void
}) {
  const {timeout = 3000, open, onClose} = props;
  const {i18n} = useTranslation();
  const {requestToken, enableNotification, isNeededToShowDialog, disableRequest} = useKupNotificationToken();
  const [show, setShow] = useState<boolean>(open ?? false);

  const handleClose = () => {
    setShow(false);
    onClose?.();
  }

  const handleSkip = () => {
    disableRequest();
    handleClose();
  }

  const handleConfirm = () => {
    requestToken();
    enableNotification();
    handleClose();
  }


  useEffect(() => {
    if (!isNeededToShowDialog) {
      onClose?.();
    }
  }, [isNeededToShowDialog]);

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        setShow(true);
      }, timeout)
    } else {
      setShow(true);
    }
  }, [timeout]);

  if (!isNeededToShowDialog) {
    return null;
  }

  if (open === false) {
    return null;
  }

  if (!open && !isNeededToShowDialog) {
    return null;
  }

  if (!show) {
    return null;
  }


  return (
    <Dialog
      title={
        i18n.t('shop-myOrder-stayUpdatedAlerts') // Stay Updated with Important Alerts!
      }
      open={show}
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirm={
        i18n.t('shop-myOrder-turnOn') // Turn On
      }
      onCancel={handleSkip}
      cancel={
        i18n.t('shop-myOrder-skip') // Skip
      }
    >
      <Container
        px={'20px'}
        stackProps={{
          gap: '8px'
        }}
      >

        <Typography color={'typography.secondary'} whiteSpace={'pre-wrap'}>
          {
            i18n.t('shop-myOrder-realTimeUpdatesPrompt') // Get real-time updates on important information. You can adjust your notification settings at any time.\nIf you skip, notification requests will be hidden for 6 hours.
          }
          <br/><br/>
          <strong>
            {
              i18n.t('shop-myOrder-previouslyBlockedNotifications') // Previously blocked notifications for this device?
            }
          </strong><br/>
          {
            i18n.t('shop-myOrder-changeSettingsPrompt') // If you previously blocked notifications, you need to change the browser or app settings.
          }<br/>
          {
            i18n.t('shop-myOrder-iosNotificationSettings') // On iOS, go to Settings > Notifications > Kup and enable notifications.
          }<br/>
          {
            i18n.t('shop-myOrder-androidNotificationSettings') // On Android, go to Settings > Apps & notifications > Kup > Notifications and enable them.
          }<br/>
        </Typography>

      </Container>
    </Dialog>
  )
}
