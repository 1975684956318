import {ReactNode} from "react";
import {Outlet, useLocation} from "react-router-dom";

import useProductsKeepState from "~/hooks/useProductsKeepState.ts";
import ProductList from "~/components/ProductList";
import useProductCategories from "~/pages/shop/hooks/useProductCategories";
import styled from '~/components/styled';
import Container from "~/components/Container";
import Page from "~/components/Page.tsx";
import PromotionSection from '~/components/PromotionSection.tsx';

import HeaderMenu from "./components/HeaderMenu.tsx";
import OrderDelivery from "./components/OrderDelivery.tsx";
import IconMenu from "~/components/IconMenu.tsx";
import Stack from "~/components/Stack.tsx";
import useSyncBasket from "~/pages/shop/hooks/useSyncBasket.ts";
import BaseLayout from "~/layouts/BaseLayout.tsx";
import MainLayout from "~/layouts/MainLayout.tsx";
import OrderDeliveryTimeLimit from "~/pages/shop/main/components/DeliveryTimeLimit.tsx";
import Headline from "~/components/Headline.tsx";
import OrderPopup from "~/pages/shop/main/components/OrderPopup.tsx";
import useKupStore from "~kup/store";
import CommonFooter from "~/components/CommonFooter.tsx";
import Divider from "~/components/Divider.tsx";
import Logo from "~/components/Logo";
import ImageView from '~/components/ImageView.tsx';
import usePromotions from '~/hooks/usePromotions.ts';
import {useTranslation} from "react-i18next";


const GradientContainer = styled(Container)({
  background: "linear-gradient(0deg, #FFF -32.32%, #E3EEFF 109.39%)",
});


export default function ShopMainPage({hashPages}: { hashPages: (hash: string) => ReactNode | undefined }) {
  useSyncBasket();
  const location = useLocation();
  const {categories} = useProductCategories();
  const {checkOrderPopup, setCheckOrderPopup} = useKupStore((state) => ({
    checkOrderPopup: state.checkOrderPopup,
    setCheckOrderPopup: state.setCheckOrderPopup
  }));
  const {i18n} = useTranslation();
  // const [sortingOrder, setSortingOrder] = useState<string>(PRODUCT_SORT_TYPE[0].value);
  // const [delivery, setDelivery] = useState<boolean>(false);
  const {products: highlightProducts} = useProductsKeepState('head', {category: 0}, {pageSize: 4, continuous: true,});
  const {products, hasNextPage, next,} = useProductsKeepState('main', {}, {pageSize: 4, continuous: true,});
  const {promotions} = usePromotions({isHighlight: true});

  // const handleChangeSortingOrder: SelectProps['onChange'] = (e) => {
  //   setSortingOrder(e.target.value as string);
  // }

  if (location.hash.startsWith('#')) {
    return hashPages(location.hash);
  }

  if (location.pathname.split('/').length > 2) { // pahtname이 더 긴 경우
    return (
      <BaseLayout>
        <Outlet/>
      </BaseLayout>
    )
  }

  return (
    <MainLayout restoreScroll={false}>
      <Page
        type={"main"}
        navigationBarProps={
          {
            lead: (
              <Logo color={'typography.tertiary'} height={'20px'}>shop</Logo>
            ),
            position: {
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }
          }}
      >
        <GradientContainer
          pt={'52px'}
          px={"20px"}
        >
          <Container
            pt={'12px'}
          >
            <ImageView src={'/best_price_guarantee.png'} width={'100%'} objectFit={'contain'}/>
          </Container>
          <Container
            pt={'20px'}
            pb={'12px'}
            stackProps={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Headline size={'large'}
                      headline={
                        i18n.t('shop-main-bestPriceOnMustHaves') // Best Price on Must-Haves!
                      }
                      headlineProps={{whiteSpace: 'pre-wrap'}}
                      subHeadline={
                        i18n.t('shop-main-orderNowGetItToday') // Order Now, Get it Today
                      }/>
            <OrderDeliveryTimeLimit/>

          </Container>

          <Container
            py={"12px"}
          >
            <HeaderMenu/>
          </Container>
          <Container
            pb={"20px"}
          >
            <OrderDelivery/>
          </Container>
        </GradientContainer>

        <Container
          stackProps={{
            column: true,
            gap: '32px'
          }}
        >
          <Container>
            <Container
              pt={"20px"}
              pb={"8px"}
              sx={{
                overflowX: 'scroll',
              }}
            >
              <Stack
                row
                gap={'16px'}
                px={'20px'}
              >
                {[...categories,].map(({slug, name}) => {
                  return (<IconMenu key={slug} icon={slug} label={name} to={`/shop#category/${slug}`}/>)
                })}
              </Stack>
            </Container>
            {/*<Container*/}
            {/*    stackProps={{*/}
            {/*      row: true,*/}
            {/*      justifyContent: 'flex-start',*/}
            {/*      gap: '8px',*/}
            {/*      py: '12px',*/}
            {/*      px: '20px'*/}
            {/*    }}*/}
            {/*>*/}
            {/*  <ToggleButton*/}
            {/*      selected={delivery}*/}
            {/*      onChange={() => {*/}
            {/*        setDelivery((prev) => !prev);*/}
            {/*      }}*/}
            {/*      value={"10PM Delivery"}*/}
            {/*  >*/}
            {/*    <Logo height={"0.875rem"} color={"inherit"}>*/}
            {/*      delivery*/}
            {/*    </Logo>*/}
            {/*  </ToggleButton>*/}

            {/*  <Select*/}
            {/*      id={"shop-select-sortingOrder"}*/}
            {/*      value={sortingOrder}*/}
            {/*      onChange={handleChangeSortingOrder}*/}
            {/*      sx={{*/}
            {/*        paddingTop: '4px',*/}
            {/*        paddingBottom: '4px'*/}
            {/*      }}*/}
            {/*      menus={*/}
            {/*        PRODUCT_SORT_TYPE.map(({label, value}) => ({*/}
            {/*          label: <Typography variant={'FootNote_B'}>{label}</Typography>,*/}
            {/*          value: value,*/}
            {/*        }))*/}
            {/*      }*/}
            {/*  />*/}
            {/*</Container>*/}
            <ProductList type={"vertical"} products={highlightProducts}/>

          </Container>

          {
            (promotions ?? []).map((promotion) => (
              <Container>
                <PromotionSection key={promotion.id} promotion={promotion}/>
              </Container>
            ))
          }

          {/*<ProductList type={"horizontal"} title={"Don't miss this product!"} products={products}/>*/}
          <ProductList
            type={"pair"}
            title={
              i18n.t('shop-main-dontMissThis') // You Don’t Want to Miss This!
            }
            products={products}
            onInfiniteScroll={hasNextPage ? () => {
              next()
            } : undefined}/>
        </Container>
        <Divider type={'box'} color={'fill'}/>
        <CommonFooter/>
      </Page>
      <OrderPopup open={!checkOrderPopup} onClose={() => setCheckOrderPopup(true)}/>
    </MainLayout>
  )
}
