import {useState} from "react";
import {Team} from "~kint/models/Team.ts";
import Container from "~/components/Container.tsx";
import Typography from "~/components/Typography.tsx";
import Paper from "~/components/Paper.tsx";
import Box from '~/components/Box';
import ImagePreviewer from "~/components/ImagePreviewer.tsx";
import {useTranslation} from "react-i18next";

function Circle() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g filter="url(#filter0_d_36_292)">
          <circle cx="8" cy="8" r="4" fill="white"/>
        </g>
        <defs>
          <filter id="filter0_d_36_292" x="0" y="0" width="16" height="16" filterUnits="userSpaceOnUse">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_36_292"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_36_292" result="shape"/>
          </filter>
        </defs>
      </svg>
  )
}

export default function BusCard(props: { bus: Team['bus'] }) {
  const {bus} = props;
  const {i18n} = useTranslation();
  const [openImageViewer, setOpenImageViewer] = useState<boolean>(false);
  const toggleOpenImageViewer = () => {
    setOpenImageViewer((prev) => !prev);
  }

  return (
      <>
        <Container
            stackProps={{
              column: true,
              gap: '16px'
            }}
        >
          <Typography variant={'BaseL_B'} color={'typography.primary'} component={'p'}>
            {i18n.t('home-main-checkBus')}
          </Typography>
          <Paper
              elevation={1}
              sx={{
                overflow: 'clip'
              }}
          >
            {bus?.image &&
                <img src={bus.image} alt="" style={{
                  width: '100%',
                  height: '240px',
                  maxHeight: '240px',
                  objectFit: 'cover',
                  objectPosition: 'bottom'
                }}
                     onClick={toggleOpenImageViewer}
                />
            }
            <Container
                px={'20px'}
                py={'8px'}
            >
              <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: '16px',
                    py: '8px',
                    border: 'solid 1px ' + theme.palette.typography.primary,
                    borderRadius: '8px'
                  })}
              >
                <Circle/>
                <Typography variant={'TitleL_B'} color={'typography.primary'} component={'p'}>
                  {bus?.number ?? ''}
                </Typography>
                <Circle/>
              </Box>
            </Container>
          </Paper>
        </Container>
        {
            bus?.image &&
            <ImagePreviewer key={bus.image} open={openImageViewer} onClose={toggleOpenImageViewer}
                            fileUrl={bus.image}/>
        }

      </>
  )
}
