import kintMessaging from "~kint/firebase/messaging.ts";
import kintFunctions from "~kint/firebase/functions.ts";

export async function requestPermission(): Promise<boolean> {
  return await kintMessaging.requestPermission();
}


type RegisterResponseBareType = { success: boolean, devices: { [deviceId: string]: number } }

export async function registerToken(participantId:string, token:string){
  return await kintFunctions.call<RegisterResponseBareType>('registerDeviceForChat', {
    participantId,
    device: token,
  }).catch(console.error)
}


export async function requestTokenForChat(participantId: string): Promise<string | null> {
  const token = await kintMessaging.requestToken();
  if (!token) return null;
  registerToken(participantId, token);
  return token;
}

export async function getTokenForChat(participantId: string): Promise<string | null> {
  const token = await kintMessaging.getToken();
  if (!token) return null;
  registerToken(participantId, token);
  return token;
}

export async function requestToken(): Promise<string | null> {
  return await kintMessaging.requestToken();
}

export async function stopChatNotification(participantId: string): Promise<void> {
  await kintFunctions.call('enableNotificationForChats', {participantId, enable:false}).catch(console.error);
}
export async function startChatNotification(participantId: string): Promise<void> {
  await kintFunctions.call('enableNotificationForChats', {participantId, enable:true}).catch(console.error)
}

export async function isPermissionGranted(): Promise<boolean> {
  return await kintMessaging.isPermissionGranted();
}

export function isSupported(): Promise<boolean> {
  return kintMessaging.isSupported();
}

export async function getToken(): Promise<string | null> {
  return await kintMessaging.getToken();
}

