import {useState} from 'react';
import useKupStore from '~kup/store';
import Page from '~/components/Page';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Container from '~/components/Container.tsx';
import Headline from '~/components/Headline.tsx';
import TextField, {TextFieldProps} from '~/components/TextField.tsx';
import Button from '~/components/Button.tsx';
import Slide from '~/components/Slide';
import NaverMap from '~/components/NaverMap.tsx';
import {Edit} from "@mui/icons-material";
import {IconButton, Stack} from "@mui/material";
import Typography from "~/components/Typography.tsx";
import useSearchAddress from "~/pages/shop/address/hooks/useSearchAddress.ts";
import {useSnackBar} from "~/contexts/SnackBarContext.tsx";
import FillBox from "~/components/FillBox.tsx";
import {useTranslation} from "react-i18next";

export default function ShopAddressCheckPage() {
  const {
    setInputAddress,
    setDeliveryAddress,
  } = useKupStore((state) => ({
    setDeliveryAddress: state.setDeliveryAddress,
    setInputAddress: state.setInputAddress,
  }));

  const navigate = useNavigate();
  const location = useLocation();
  const {showSnackBar} = useSnackBar();
  const {i18n} = useTranslation();
  const [searchInputAddress, setSearchInputAddress] = useState<string>(location?.state?.inputAddress as string);
  const [detail, setDetail] = useState<string>('');
  const [request, setRequest] = useState<string>('');
  const [address, setAddress] = useState(location?.state?.address)
  const [step, setStep] = useState<0 | 1 | 2 | 3>(0);
  const {loading, fetchAddresses} = useSearchAddress();

  const center = {
    lat: address?.location.latitude ?? 37.56100278,
    lng: address?.location.longitude ?? 126.9996417,
  };

  const handleEditAddress = () => {
    setStep(1);
  }

  const handleCheckAgain = async () => {
    if (searchInputAddress) {
      setSearchInputAddress(searchInputAddress);
      const addresses = await fetchAddresses(searchInputAddress)
      if (addresses.length === 0) {
        showSnackBar({message: i18n.t('shop-address-addressNotFound') // Address not found. Please verify and try again.
          , lift: true});
        return;
      }
      setAddress(addresses[0]);
      setStep(0);
    }
  };

  const handleConfirmAddress = () => {
    setInputAddress(searchInputAddress);
    setStep(2);
  }

  const handleChangeDetail: TextFieldProps['onChange'] = (e) => {
    setDetail(e.target.value);
  };

  const handleChangeRequest: TextFieldProps['onChange'] = (e) => {
    setRequest(e.target.value);
  };

  const handleToRequest = () => {
    setStep(3);
  }

  const handleConfirm = () => {
    if (address) {
      setDeliveryAddress({
        address,
        detail,
        request,
      });
    }
    setInputAddress(searchInputAddress);
    navigate(-2);
  };

  return (
    <Page
      type={'modal'}
      fixedBottom={
        <Container
          bottom
          background={'paper'}
          pt={'8px'}
          sx={{
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          }}
        >{
          step === 0 && (
            <Slide in={step === 0} direction={'right'} unmountOnExit mountOnEnter>
              <div style={{zIndex: 200}}>
                <Container
                  py={'12px'}
                  px={'20px'}
                >
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Headline
                      headline={`${address?.formattedAddress}` ?? 'searching...'}
                      description={address.displayName?.text ?? searchInputAddress}
                      headlineProps={{
                        whiteSpace: 'pre-wrap'
                      }}
                      size={'medium'}
                    />
                    <IconButton
                      onClick={handleEditAddress}
                    >
                      <Edit/>
                    </IconButton>
                  </Stack>
                </Container>
                <Container
                  py={'8px'}
                  px={'20px'}
                >

                  <Button
                    fullWidth
                    onClick={handleConfirmAddress}
                  >
                    {
                      i18n.t('shop-address-rightPosition') // This is the right position!
                    }
                  </Button>
                </Container>
              </div>
            </Slide>)
        }
          {step === 1 &&
              <Slide in={step === 1} direction={'left'} unmountOnExit mountOnEnter>
                <div style={{zIndex: 200}}>
                  <Container
                      stackProps={{
                        column: true,
                        gap: '8px',
                      }}
                      py={'12px'}
                      px={'20px'}
                  >
                    <TextField
                        fullWidth
                        placeholder={
                          i18n.t('shop-address-enterAddressHere') // Enter Address Here (Available in Seoul Only)
                        }
                        value={searchInputAddress}
                        onChange={(e) => setSearchInputAddress(e.target.value)}
                    />
                    <FillBox color={'fill'} flexDirection={'column'}>
                      <Typography component={'p'} variant={'FootNote_B'} color={'text.secondary'}>
                        {
                          i18n.t('shop-address-example') // Example
                        }
                      </Typography>
                      <Typography component={'p'} variant={'FootNote_B'} color={'text.secondary'}>
                        110 Sejong-daero, Jung-gu, Seoul
                      </Typography>
                      <Typography component={'p'} variant={'FootNote_B'} color={'text.secondary'}>
                        12, Supyo-ro, Seoul
                      </Typography>
                      <Link
                          color={'fill'}
                          to={'/shop/address/search'}
                          style={{
                            marginTop: '4px'
                          }}
                      >
                        <Typography
                            sx={{
                              width: '100%',
                              display: 'flex',
                              textDecoration: 'underline'
                            }}
                            color={'text.secondary'}
                            variant={'FootNote_B'}
                        >
                          {
                            i18n.t('shop-address-findAddressByHotel') // Find address by hotel/building name
                          }
                        </Typography>
                      </Link>
                    </FillBox>
                  </Container>
                  <Container
                      py={'8px'}
                      px={'20px'}
                  >
                    <Button
                        fullWidth
                        onClick={handleCheckAgain}
                        loading={loading}
                    >
                      {
                        i18n.t('shop-address-checkAgain') // Check Again
                      }
                    </Button>
                  </Container>
                </div>
              </Slide>
          }
          {address &&
              <Slide in={step === 2} direction={'left'} unmountOnExit mountOnEnter>
                <div style={{zIndex: 200}}>
                  <Container
                      py={'12px'}
                      px={'20px'}
                  >
                    <Headline
                        headline={
                          i18n.t('shop-address-enterDetailedAddress') // Please enter the detailed address
                        }
                        description={address?.formattedAddress}
                        size={"medium"}/>
                  </Container>
                  <Container
                      stackProps={{
                        column: true,
                        gap: '4px',
                      }}
                      px={'20px'}
                  >
                    <TextField
                        fullWidth
                        placeholder={
                          i18n.t('shop-address-enterHere') // Enter Here (ex Room Number, floor...)
                        }
                        onChange={handleChangeDetail}
                    />
                  </Container>
                  <Container
                      pt={'16px'}
                      pb={'8px'}
                      px={'20px'}
                  >
                    <Button
                        fullWidth
                        onClick={handleToRequest}
                    >
                      {
                        i18n.t('shop-address-next') // Next
                      }
                    </Button>
                  </Container>
                </div>
              </Slide>
          }
          <Slide in={step === 3} direction={'left'} unmountOnExit mountOnEnter>
            <div style={{zIndex: 200}}>
              <Container
                py={'12px'}
                px={'20px'}
              >
                <Headline
                  headline={
                    i18n.t('shop-address-instructionsOrRequests') // Any instructions or requests?
                  }
                  description={
                    i18n.t('shop-address-lobbyPickupRequired') // Lobby or entrance pickup may be required due to hotel policy
                  }
                  size={"medium"}/>
              </Container>
              <Container
                stackProps={{
                  column: true,
                  gap: '8px',
                }}
                py={'8px'}
                px={'20px'}
              >
                <TextField
                  fullWidth
                  placeholder={
                    i18n.t('shop-address-deliveryInstructions') // Instruction for your delivery
                  }
                  onChange={handleChangeRequest}
                />
              </Container>
              <Container
                py={'8px'}
                px={'20px'}
              >
                <Button
                  fullWidth
                  onClick={handleConfirm}
                >
                  {
                    i18n.t('shop-address-confirm') // Confirm
                  }
                </Button>
              </Container>
            </div>
          </Slide>
        </Container>
      }
    >
      <Container>
        {address &&
            <NaverMap
                disableTouch
                center={center}
                zoom={15}
                placeName={
                  i18n.t('shop-address-rightPlace') // Is this the right place?
                }
                containerStyles={{width: '100%', height: 'calc(100vh - 212px)'}}/>
        }
      </Container>
    </Page>
  );
}
