import Dialog from "~/components/Dialog.tsx";
import Container from "~/components/Container.tsx";
import Typography from "~/components/Typography.tsx";
import useKintStore from "~kint/store";
import useChatNotificationToken from "~/hooks/useChatNotificationToken.ts";
import useKupNotificationToken from "~/hooks/useKupNotificationToken.ts";
import {useTranslation} from "react-i18next";


export default function TurnOffNotificationDialog(props: {
  open: boolean,
  onClose: () => void
}) {
  const {open, onClose} = props;
  const {i18n} = useTranslation();
  const {participant} = useKintStore((state)=>({
    participant:state.participant,
  }));

  const {disableNotification} = useKupNotificationToken();
  const {disableChatNotification} = useChatNotificationToken();


  const handleTurnOff = ()=>{
    disableNotification()
    if(participant)
      disableChatNotification();
    onClose();
  }

  return (
      <Dialog
          title={i18n.t('message-chat-stayUpdatedImportantInfo')}
          open={open}
          onClose={onClose}
          onConfirm={onClose}
          confirm={i18n.t('message-chat-keepTurnOn')}
          onCancel={handleTurnOff}
          cancel={i18n.t('message-chat-turnOff')}
      >
        <Container
            px={'20px'}
            stackProps={{
              gap: '8px'
            }}
        >
          <Typography color={'typography.secondary'} whiteSpace={'pre-wrap'} mt={2}>
            <strong>{
              i18n.t('message-chat-beforeTurnOffNotifications') // Before You Turn Off Notifications
            }</strong><br/>
            {
              i18n.t('message-chat-considerStayingUpdated') // Please consider staying updated with the latest updates. Missing out on important updates might cause you to miss crucial information.
            }<br/><br/>
            <strong>
              {
                i18n.t('message-chat-wantToBlockForDevice') // Want to block for this device?
              }
            </strong><br/>
            If you decide to block notifications, you can always disable it on your browser settings.<br/><br/>
            <strong>Installed?</strong><br/>
            {'On iOS, go to Settings > Notifications > Kup and enable notifications.'}<br/>
            {'On Android, go to Settings > Apps & notifications > Kup > Notifications and enable them.'}<br/>
          </Typography>
        </Container>
      </Dialog>
  )
}
