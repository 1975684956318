import {useLayoutEffect, useState} from "react";
import {
  requestTokenForChat,
  getTokenForChat,
  isPermissionGranted,
  isSupported, startChatNotification
} from "~kint/controllers/notification.ts";
import useKintStore from "~kint/store";
import {stopChatNotification} from "~kint/controllers/notification.ts";

export default function useChatNotificationToken() {
  const [isNotificationSupported, setIsNotificationSupported] = useState<boolean>(false);
  const [isNotificationGranted, setIsNotificationGranted] = useState<boolean>(false);
  const {
    participant,
    token,
    setToken,
    isChatNotificationDisabled,
    isNotificationPermissionRequested,
    setIsNotificationPermissionRequested,
    stopChatRequestNotificationPermission,
    setStopChatRequestNotificationPermission,
    setIsChatNotificationDisabled
  } = useKintStore((state) => ({
    participant: state.participant,
    setIsNotificationPermissionRequested: state.setIsNotificationPermissionRequested,
    isNotificationPermissionRequested: state.isNotificationPermissionRequested,
    setStopChatRequestNotificationPermission: state.setStopChatRequestNotificationPermission,
    stopChatRequestNotificationPermission: state.stopChatRequestNotificationPermission,// request skip한 시간
    isChatNotificationDisabled: state.isChatNotificationDisabled,
    setIsChatNotificationDisabled: state.setIsChatNotificationDisabled,
    token: state.token,
    setToken: state.setToken
  }));

  const participantId = participant?.id;

  const isSnoozed = stopChatRequestNotificationPermission > Date.now() - 12 * 60 * 60 * 1000;

  const disableRequest = () => {
    const now = Date.now();
    setStopChatRequestNotificationPermission(now);
    setIsNotificationPermissionRequested(true);
  }

  const disableChatNotification = () => {
    setIsChatNotificationDisabled(true);
    if (participantId)
      stopChatNotification(participantId).catch(console.error);
  }

  const enableChatNotification = () => {
    setIsChatNotificationDisabled(false);
    if (participantId) {
      startChatNotification(participantId).catch(console.error);
    }
  }

  const requestToken = () => {
    if (participantId) {
      requestTokenForChat(participantId)
          .then((token) => {
            setToken(token);
          })
          .catch(console.error)
          .finally(() => {
            setIsNotificationPermissionRequested(true);
          })
    }
  }

  useLayoutEffect(() => {
    isSupported().then(setIsNotificationSupported);

    if (isChatNotificationDisabled) { //disable 일 경우 토큰 갱신 하지 아니함
      return;
    }
    isPermissionGranted().then((isNotificationGranted)=>{
      setIsNotificationGranted(isNotificationGranted);
      setIsNotificationPermissionRequested(true);
      if (participantId && isNotificationGranted) {
        getTokenForChat(participantId).then((token) => {
          setToken(token);
        })
      }
    });
  }, [participantId]);

  return {
    token,
    requestToken,
    disableRequest,
    disableChatNotification,
    enableChatNotification,
    isChatNotificationDisabled,
    isNotificationPermissionRequested,
    isNotificationSupported,
    isNotificationGranted,
    isSnoozed,
    stopChatRequestNotificationPermission,
    isNeededToShowDialog: isNotificationSupported && !isNotificationGranted && !isSnoozed && !isChatNotificationDisabled
  };
}
