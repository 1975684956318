import {useEffect} from "react";
import {getDiscoveryCategories} from "~kup/controllers/discovery.ts";
import {DiscoveryCategory} from "~kup/models/Discovery.ts";
import {useKeepState} from "~/hooks/useKeepState.ts";


type UseCategories = { categories: DiscoveryCategory[] };

export default function useDiscoveryCategories(): UseCategories {
  const [categories, setCategories] = useKeepState<DiscoveryCategory[]>('categories', []);
  useEffect(() => {
    getDiscoveryCategories().then((discoveryCategories) => setCategories(discoveryCategories))
  }, []);
  return {categories}
}
