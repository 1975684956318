import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Order } from '~kup/models/Order.ts';
import Page from '~/components/Page';
import Spotlight from '~/components/Spotlight.tsx';
import Button from '~/components/Button.tsx';
import Container from '~/components/Container.tsx';
import Icon from '~/components/Icon';
import useSyncBasket from '~/pages/shop/hooks/useSyncBasket.ts';

import { PAYMENT_METHOD } from '~/pages/shop/constants';
import {useTranslation} from "react-i18next";

export default function ShopOrderTransactionPage() {
  useSyncBasket();
  const navigate = useNavigate();
  const location = useLocation();
  const {i18n} = useTranslation();
  const [remainTime, setRemainTime] = useState<number>(3);
  const order = location.state?.order as Order;
  const paymentMethod = order.paymentMethod;
  const orderDetailLink = `/shop/orders/${order.id}`;

  const guidanceMessages = {
    // [PAYMENT_METHOD[0].value]: {
    //   message: 'Pay on Guide (On-Site, Cash)\nGuide will ask you for payment',
    //   trail: null,
    // },
    // [PAYMENT_METHOD[0].value]: {
    //   'message': 'Pay on Delivery (On-Site, Cash)\nWe will meet you between 9pm ~ 12am (midnight)',
    //   trail: null,
    // },
    [PAYMENT_METHOD[0].value]: {
      message: i18n.t('shop-order-cardPaymentPage') // Card payment page will show up
      ,
      trail: <Button>Request payment</Button>,
    },
    // [PAYMENT_METHOD[1].value]: {
    //   message: 'Alipay payment page will show up',
    //   trail: <Button>Request payment</Button>,
    // },
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRemainTime(prevState => prevState - 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [remainTime]);

  if (!order) return <Navigate to={'/shop/orders'} replace />;
  if (remainTime <= 0) return <Navigate to={orderDetailLink} replace />;

  return (
    <Page
      type={'modal'}
      name={
        i18n.t('shop-order-order') // Order
      }
      backgroundColor={'paper'}
      fixedBottomPosition={'fixed'}
      fixedBottom={
        <Container
          bottom
          background={'paper'}
          sx={() => ({ zIndex: 1 })}
        >
          <Container
            pb={'12px'}
            px={'20px'}
          >
            <Button
              fullWidth
              variant={'contained'}
              onClick={() => {
                navigate(orderDetailLink, { replace: true });
              }}
            >
              {
                i18n.t('shop-order-goToOrderDetail', { remainTime }) // Go to Order Detail ({remainTime}s)
              }
            </Button>
          </Container>
        </Container>
      }
    >
      <Spotlight
        textAlign={'center'}
        lead={
          <Icon
            type={'circle'}
            size={'40px'}
            width={'48px'}
            backgroundColor={'typography.tertiary'}
          >
            check
          </Icon>
        }
        headline={
          i18n.t('shop-order-orderProcessed') // Your order has been processed
        }
        description={guidanceMessages[paymentMethod].message}
        descriptionProps={{
          whiteSpace: 'pre-wrap',
        }}
        trail={guidanceMessages[paymentMethod].trail}
      />
    </Page>
  );
}
