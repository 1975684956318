export interface DiscoveryLocation {
  address: string;
  area: string;
  subway?: string;
  nearBy?: string;
  latlng: [number, number];
  googleMap: string;
}

export interface DiscoveryInformationPair {
  label: string,
  value: string,
}

export interface DiscoveryRecommendation {
  category: string;
  image?: string;
  subheader?: string;
  price?: string;
  name: string;
  description: string;
}

export interface DiscoveryPlaceResponse {
  id: string;
  slug: string;
  images: string[];
  name: string;
  priceLevel: number;
  tags: string[];
  description: string;
  html: string;
  location: DiscoveryLocation;
  information: DiscoveryInformationPair[];
  recommendations: DiscoveryRecommendation[];
  createdAt: string;
  updatedAt: string;
}

export interface DiscoveryCategoryResponse {
  id: string;
  slug: string;
  name: string;
  isPreparing?: boolean;
  createdAt: string;
  updatedAt: string;
}


export class DiscoveryPlace {
  id: string;
  slug: string;
  images: string[];
  name: string;
  priceLevel: number;
  tags: string[];
  description: string;
  location: DiscoveryLocation;
  information: DiscoveryInformationPair[];
  recommendations: DiscoveryRecommendation[];
  html: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(raw: DiscoveryPlaceResponse) {
    this.id = raw.id;
    this.slug = raw.slug;
    this.images = [...raw.images];
    this.name = raw.name;
    this.priceLevel = raw.priceLevel;
    this.tags = [...raw.tags];
    this.description = raw.description;
    this.location = {...raw.location}
    this.information = raw.information.map((i) => ({...i}));
    this.recommendations = raw.recommendations.map(r => ({...r}));
    this.html = raw.html;
    this.createdAt = new Date(raw.createdAt);
    this.updatedAt = new Date(raw.updatedAt);
  }
}


export class DiscoverySimple {
  id: string;
  slug: string;
  category: string;
  title: string;
  description: string;
  images: string[];
  createdAt: Date;
  updatedAt: Date;
  order: number;
  places?: DiscoveryPlaceSimple[];

  constructor(raw: DiscoverySimpleResponse) {
    this.id = raw.id;
    this.slug = raw.slug;
    this.category = raw.category;
    this.title = raw.title;
    this.description = raw.description;
    this.images = [...raw.images];
    this.createdAt = new Date(raw.createdAt);
    this.updatedAt = new Date(raw.updatedAt);
    this.order = raw.order;
    this.places = raw.places ? raw.places.map(ps => new DiscoveryPlaceSimple(ps)) : undefined;
  }
}

export class Discovery {
  id: string;
  slug: string;
  category: string;
  title: string;
  description: string;
  images: string[];
  createdAt: Date;
  updatedAt: Date;
  order: number;
  mapZoom: number;
  places?: DiscoveryPlaceSimple[];
  products?: DiscoveryProductSimple[];
  objects?: DiscoveryObjectSimple[];
  html: string;

  constructor(raw: DiscoveryResponse) {
    this.id = raw.id;
    this.slug = raw.slug;
    this.category = raw.category;
    this.title = raw.title;
    this.description = raw.description;
    this.images = [...raw.images];
    this.createdAt = new Date(raw.createdAt);
    this.updatedAt = new Date(raw.updatedAt);
    this.order = raw.order;
    this.places = raw.places ? raw.places.map(ps => new DiscoveryPlaceSimple(ps)) : undefined;
    this.products = raw.products ? raw.products.map(p => new DiscoveryProductSimple(p)) : undefined;
    this.objects = raw.objects ? raw.objects.map(o => new DiscoveryObjectSimple(o)) : undefined;
    this.html = raw.html;
    this.mapZoom = raw.mapZoom ?? 13;
  }
}


export interface DiscoverySimpleResponse {
  id: string;
  slug: string;
  category: string;
  title: string;
  description: string;
  images: string[];
  createdAt: string;
  updatedAt: string;
  order: number;
  places?: DiscoveryPlaceSimpleResponse[];
  products?: DiscoveryProductSimpleResponse[];
  objects?: DiscoveryObjectSimpleResponse[];
}


export interface DiscoveryResponse extends DiscoverySimpleResponse {
  id: string;
  slug: string;
  category: string;
  title: string;
  description: string;
  images: string[];
  createdAt: string;
  updatedAt: string;
  order: number;
  mapZoom?: number;
  places?: DiscoveryPlaceSimpleResponse[];
  products?: DiscoveryProductSimpleResponse[];
  objects?: DiscoveryObjectSimpleResponse[];
  html: string;
}


export class DiscoveryCategory {
  static CATEGORY_DEFAULT = 'discovery-food' as const;

  id: string;
  slug: string;
  name: string;
  isPreparing?: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(raw: DiscoveryCategoryResponse) {
    this.id = raw.id;
    this.slug = raw.slug;
    this.name = raw.name;
    this.isPreparing = raw.isPreparing;
    this.createdAt = new Date(raw.createdAt);
    this.updatedAt = new Date(raw.updatedAt);
  }
}

export interface DiscoveryPlaceSimpleResponse {
  id: string;
  slug: string;
  url: string;
  images: string[];
  name: string;
  priceLevel: number;
  tags: string[];
  description: string;
  address: string;
  latlng: [number, number];
}

export class DiscoveryPlaceSimple {
  id: string;
  slug: string;
  url: string;
  images: string[];
  name: string;
  priceLevel: number;
  tags: string[];
  description: string;
  address: string;
  latlng: [number, number];

  constructor(raw: DiscoveryPlaceSimpleResponse) {
    this.id = raw.id;
    this.slug = raw.slug;
    this.url = raw.url;
    this.images = [...raw.images];
    this.name = raw.name;
    this.priceLevel = raw.priceLevel;
    this.tags = [...raw.tags];
    this.description = raw.description;
    this.address = raw.address;
    this.latlng = [...raw.latlng];
  }
}


export interface DiscoveryObjectSimpleResponse {
  type: string;
  id: string;
  slug: string;
  url: string;
  images: string[];
  name: string;
  tags: string[];
  description: string;
}


export interface DiscoveryProductSimpleResponse extends DiscoveryObjectSimpleResponse {
  type: 'product';
  id: string;
  slug: string;
  url: string;
  images: string[];
  name: string;
  tags: string[];
  price: string;
  description: string;
}


export class DiscoveryObjectSimple {
  type: string;
  id: string;
  slug: string;
  url: string;
  images: string[];
  name: string;
  tags: string[];
  description: string;

  constructor(raw: DiscoveryObjectSimpleResponse) {
    this.type = raw.type;
    this.id = raw.id;
    this.slug = raw.slug;
    this.url = raw.url;
    this.images = [...raw.images];
    this.name = raw.name;
    this.tags = [...raw.tags];
    this.description = raw.description;
  }
}

export class DiscoveryProductSimple {
  type: 'product';
  id: string;
  slug: string;
  url: string;
  images: string[];
  name: string;
  tags: string[];
  description: string;
  price: string;

  constructor(raw: DiscoveryProductSimpleResponse) {
    this.type = 'product';
    this.id = raw.id;
    this.slug = raw.slug;
    this.url = raw.url;
    this.images = [...raw.images];
    this.name = raw.name;
    this.tags = [...raw.tags];
    this.price = raw.price;
    this.description = raw.description;
    this.price = raw.price;
  }
}
