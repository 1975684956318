if (typeof window.ReactNativeWebView !== 'undefined') {
  // requestJob 함수 정의
  const requestJob = async <T, A extends object = object>(type: string, args?: A): Promise<T> => {
    return new Promise((resolve, reject) => {
      const listener = (event: MessageEvent) => {
        try {
          const data = JSON.parse(event.data);
          if (type === data.type) {
            const payload = data.payload; // 오타 수정: pyaload -> payload
            window.removeEventListener('message', listener); // 성공 시 리스너 제거
            resolve(payload);
          }
        } catch (error) {
          window.removeEventListener('message', listener); // 실패 시에도 리스너 제거
          reject(error);
        }
      };
      // 메시지 수신 리스너 추가
      window.addEventListener('message', listener, {capture:true});

      // React Native로 메시지 전송
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type, payload: { args } }));
    });
  };

  // window.ReactNativeWebView에 requestJob 메서드 추가
  window.ReactNativeWebView.requestJob = requestJob;
}
