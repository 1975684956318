
export type PromotionType = 'ON_TOUR' | 'DURATION' | 'QUANTITY';

export interface PromotionImageResponse{
  link:string;
  type:string;
  imageUrl:string;
}

export interface PromotionResponse {
  id: string;
  type: PromotionType;
  title: string;
  subTitle: string;
  description: string;
  promotionImages: PromotionImageResponse[];
  isActive: boolean;
  startDate?: Date;
  endDate?: Date;
}


export interface PromotionImage{
  link:string;
  type:string;
  imageUrl:string;
}

export class Promotion {
  id: string;
  type: PromotionType;
  title: string;
  subTitle: string;
  description: string;
  promotionImages: PromotionImageResponse[];
  isActive: boolean;
  startDate?: Date;
  endDate?: Date;

  constructor(raw: PromotionResponse) {
    this.id = raw.id;
    this.type = raw.type;
    this.title = raw.title;
    this.subTitle = raw.subTitle;
    this.description = raw.description;
    this.promotionImages = raw.promotionImages ?? [];
    this.isActive = raw.isActive;
    this.startDate = raw.startDate? new Date(raw.startDate) : undefined;
    this.endDate = raw.endDate? new Date(raw.endDate) : undefined;
  }
}
