import {useEffect, useState} from "react";

import useKupStore from "~kup/store";
import {OptionStock, ProductOption, ProductOptionItem, ProductStock} from "~kup/models/Product.ts";

import ModalPopup from "~/components/ModalPopup.tsx";
import List from '~/components/List';
import ListItem from "~/components/ListItem.tsx";
import ImageView from "~/components/ImageView.tsx";
import Slide from '~/components/Slide';

import {useProductContext} from "~/pages/shop/product/contexts/ProductContext.tsx";
import StockBadge from "~/pages/shop/components/StockBadge.tsx";

type ProductOptionItemWithStockType = ProductOptionItem & {
  stock: number,
  optionStock: OptionStock | null,
  optionStocks: OptionStock[]
}

export type OptionPopupProps = {
  open: boolean,
  onClose: () => void;
}

export default function OptionPopup(props: OptionPopupProps) {
  const {open, onClose} = props;
  const {productOptions, productStock, onProductOptionSelectionsCommit} = useProductContext();
  const [selections, setSelections] = useState<ProductOptionItem[]>([]);
  const [currentOptionIndex, setCurrentOptionIndex] = useState<number>(0);
  const currentOption = productOptions[currentOptionIndex];

  useEffect(() => {
    if (selections.length === productOptions.length) { // 모두 선택됨
      onProductOptionSelectionsCommit(selections);
      setSelections([]);
    }
    setCurrentOptionIndex(selections.length);
  }, [selections.length]);

  const handleSectionItem = (item: ProductOptionItem) => {
    setSelections((prev) => [...prev, item]);
  }
  const handelClose = () => {
    setSelections([]);
    onClose();
  }

  if (!productOptions.length || !productStock) return null;

  return (
    <ModalPopup
      open={open}
      title={currentOption?.name ?? ''}
      onClose={handelClose}
    >
      {
        productOptions.map((option, idx) => {
          const isCurrentOption = idx === currentOptionIndex;
          const isFirstOption = currentOptionIndex === 0;
          if (!isCurrentOption) return null;
          if (isFirstOption) {
            return (
              <OptionItemList
                key={option.id}
                option={option}
                stock={productStock}
                selectedSlug={selections.map(i => i.slug)}
                onSelect={handleSectionItem}
              />
            )
          }
          return (
            <Slide
              key={option.id}
              in={isCurrentOption}
              unmountOnExit={false}
              direction={'left'}
              timeout={isFirstOption ? 0 : 300}
            >
              <div>
                <OptionItemList
                  key={option.id}
                  option={option}
                  stock={productStock}
                  selectedSlug={selections.map(i => i.slug)}
                  onSelect={handleSectionItem}
                />
              </div>
            </Slide>)
        })
      }
    </ModalPopup>
  )
}

export type OptionListProps = {
  selectedSlug: string[];
  option: ProductOption;
  stock: ProductStock;
  onSelect: (selection: ProductOptionItem) => void;
}


export function OptionItemList(props: OptionListProps) {
  const {selectedSlug, option, stock, onSelect} = props;
  const optionItems = option.optionItems;

  const optionItemsWithStock = optionItems.map<ProductOptionItemWithStockType>((optionItem) => {
    if (!stock.optionStock) {
      return {...optionItem, stock: 0, optionStock: null, optionStocks: []};
    } else {
      const searchSlug = [...selectedSlug, optionItem.slug];
      const optionStocks = stock.getMatchingOptionStocks(searchSlug);
      const optionStock = stock.getExactOptionStock(searchSlug);
      const optionStockQuantity = stock.getMatchingOptionStockQuantity(searchSlug);
      return {...optionItem, stock: optionStockQuantity, optionStock, optionStocks};
    }
  })

  const handleClickItem = (optionItem: ProductOptionItemWithStockType) => {
    onSelect(optionItem);
  }

  return (
    <List component={'ul'}>
      {
        optionItemsWithStock.map((optionItemWithStock) => (
          <OptionListItem key={optionItemWithStock.slug} optionItem={optionItemWithStock} onClick={handleClickItem}/>
        ))
      }
    </List>
  )
}

export function OptionListItem(props: {
  optionItem: ProductOptionItemWithStockType,
  onClick: (optionItem: ProductOptionItemWithStockType, slug: string,) => void
}) {
  const {currency} = useKupStore((state) => ({currency: state.currency}));
  const {optionItem, onClick} = props;
  const {
    thumbnail,
    stock,
    sub,
    name,
    description,
    optionStock,
    optionStocks,
    slug,
  } = optionItem;


  const handleClick = () => {
    onClick(optionItem, slug)
  }

  if (optionStocks.length === 0) {
    return null;
  }

  if (optionStock) {
    return (
      <ListItem
        type={'value'}
        disabled={optionItem.stock === 0}
        value={optionStock.price.toLocaleString() + ` ${currency}`}
        head={name}
        subHead={optionItem.stock.toLocaleString() + ' left'}
        onClick={handleClick}
      />
    );
  }
  return (
    <ListItem
      type={'headline'}
      disabled={optionItem.stock === 0}
      lead={thumbnail ? <ImageView borderRadius={'6px'} width={'80px'} src={thumbnail}/> : null}
      trail={<StockBadge stock={stock}/>}
      headline={name}
      subHeadline={sub}
      description={description}
      onClick={handleClick}
    />
  )
}
