import {createTheme, Shadows, Theme} from '@mui/material/styles';
import {CSSProperties, TypographyOptions} from "@mui/material/styles/createTypography"
import {Components} from "@mui/material/styles/components"

export {default as GlobalCSS} from './GlobalCSS';


declare module '@mui/material/styles' {

  interface SystemColors {
    red: Palette['primary'];
    blue: Palette['primary'];
    green: Palette['primary'];
    orange: Palette['primary'];
    yellow: Palette['primary'];
  }

  interface SystemColorOptions {
    red?: PaletteOptions['primary'];
    blue?: PaletteOptions['primary'];
    green?: PaletteOptions['primary'];
    orange?: PaletteOptions['primary'];
    yellow?: PaletteOptions['primary'];
  }

  interface ColorTypography {
    primary: string,
    secondary: string,
    tertiary: string,
    quaternary: string,
  }

  interface ColorTypographyOptions {
    primary?: string,
    secondary?: string,
    tertiary?: string,
    quaternary?: string,
  }

  interface FillColors {
    primary: string,
  }

  interface FillColorsOptions {
    primary?: string,
  }

  interface Palette {
    system: SystemColors,
    fill: FillColors,
    scrim: FillColors,
    typography: ColorTypography;
  }

  interface PaletteOptions {
    system?: SystemColorOptions,
    typography?: ColorTypographyOptions;
    fill?: FillColorsOptions,
    scrim?: FillColorsOptions,
  }

  interface PaletteColorVariants {
    primary: Palette['primary'],
    secondary: Palette['secondary'],
    error: Palette['error'],
    warning: Palette['warning'],
    info: Palette['info'],
    success: Palette['success'],
  }

  interface TypographyVariants {
    TitleL: CSSProperties;
    TitleL_B: CSSProperties;
    TitleM: CSSProperties;
    TitleM_B: CSSProperties;
    TitleS: CSSProperties;
    TitleS_B: CSSProperties;
    BaseL: CSSProperties;
    BaseL_B: CSSProperties;
    BaseM: CSSProperties;
    BaseM_B: CSSProperties;
    BaseS: CSSProperties;
    BaseS_B: CSSProperties;
    FootNote: CSSProperties;
    FootNote_B: CSSProperties;
    Caption_B: CSSProperties;
    Caption: CSSProperties;
  }

  // Allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    TitleL?: CSSProperties;
    TitleL_B?: CSSProperties;
    TitleM?: CSSProperties;
    TitleM_B?: CSSProperties;
    TitleS?: CSSProperties;
    TitleS_B?: CSSProperties;
    BaseL?: CSSProperties;
    BaseL_B?: CSSProperties;
    BaseM?: CSSProperties;
    BaseM_B?: CSSProperties;
    BaseS?: CSSProperties;
    BaseS_B?: CSSProperties;
    FootNote?: CSSProperties;
    FootNote_B?: CSSProperties;
    Caption_B?: CSSProperties;
    Caption?: CSSProperties;
  }
}


// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    TitleL: true;
    TitleL_B: true;
    TitleM: true;
    TitleM_B: true;
    TitleS: true;
    TitleS_B: true;
    BaseL: true;
    BaseL_B: true;
    BaseM: true;
    BaseM_B: true;
    BaseS: true;
    BaseS_B: true;
    FootNote: true;
    FootNote_B: true;
    Caption_B: true;
    Caption: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    fill: true
  }
}


export const systemColors = {
  red: {
    main: '#FF3838',
    light: 'rgba(255, 56, 56, 0.4)',
    contrastText: '#FFFFFF'
  },
  orange: {
    main: '#FF7718',
    light: 'rgba(255, 119, 24, 0.4)',
    contrastText: '#FFFFFF'
  },
  yellow: {
    main: '#FDE02F',
    light: 'rgba(253, 224, 47, 0.4)',
    contrastText: '#FFFFFF'
  },
  green: {
    main: '#24D064',
    light: 'rgba(36, 208, 100, 0.4)',
    contrastText: '#FFFFFF'
  },
  blue: {
    main: '#379DFF',
    light: 'rgba(55, 157, 255, 0.4)',
    contrastText: '#FFFFFF'
  },
}

export const variantColors = {
  primary: {
    main: '#379DFF',  // Core_Primary
    // dark: '#379DFF',  // Core_Primary
    light: 'rgb(55,157,255, 0.1)',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: 'rgb(55,157,255, 0.3)',  // Core_Secondary
    light: 'rgb(55,157,255, 0.1)',
    // dark: '#83A0FF',  // Core_Secondary
    contrastText: '#101010'
  },
  error: {
    main: '#FF3838',
    light: 'rgba(255, 56, 56, 0.1)', // Red_Translucent
    contrastText: '#FFFFFF'
  },
  warning: {
    main: '#FF7718',
    light: 'rgba(255, 119, 24, 0.1)', // Orange_Translucent,
    contrastText: '#FFFFFF'
  },
  info: {
    main: '#379DFF',
    light: 'rgba(55, 157, 255, 0.1)', // Blue_Translucent,
    contrastText: '#FFFFFF'
  },
  success: {
    main: '#24D064',
    light: 'rgba(36, 208, 100, 0.1)', // Green_Translucent,
    contrastText: '#FFFFFF'
  },
}

const palette = {
  ...variantColors,

  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  background: {
    default: '#f7f7f7', // Background Primary
    paper: '#FFFFFF', // Background Secondary
  },

  fill: {
    primary: 'rgba(162,182,211,0.1)',
  },
  scrim: {
    primary: 'rgba(0,0,0, 0.6)'
  },
  divider: 'rgba(0, 0, 0, 0.06)', // Divider

  text: {
    primary: '#101010', // Primary Text
    secondary: 'rgba(16, 16, 16, 0.6)', // Secondary Text
    disabled: 'rgba(16, 16, 16, 0.3)', // Tertiary Text
  },

  typography: {
    primary: 'rgba(16, 16, 16, 1)',
    secondary: 'rgba(16, 16, 16, 0.6)',
    tertiary: 'rgba(16, 16, 16, 0.3)',
    quaternary: 'rgba(16, 16, 16, 0.16)',
  },

  system: systemColors
};
const typography: TypographyOptions = {
  htmlFontSize: 16,
  fontFamily: '"SF Pro", "Inter", "Roboto", "Noto Sans TC", "Noto Sans SC", "Noto Sans JP", sans-serif',
  h1: {
    fontSize: '1.75rem', // 28px
    fontWeight: 400,
    lineHeight: '3rem', // 48px
    letterSpacing: '-0.03em',
  },
  h2: {
    fontSize: '1.5rem', // 24px
    fontWeight: 400,
    lineHeight: '2.5rem', // 40px
    letterSpacing: '-0.03em',
  },
  h3: {
    fontSize: '1.25rem', // 20px
    fontWeight: 400,
    lineHeight: '2.125rem', // 34px
    letterSpacing: '-0.03em',
  },
  h4: {
    fontSize: '1.125rem', // 18px
    fontWeight: 400,
    lineHeight: '2.75rem', // 44px
    letterSpacing: '-0.03em',
  },
  h5: {
    fontSize: '1rem', // 16px
    fontWeight: 400,
    lineHeight: '1.75rem', // 28px
    letterSpacing: '-0.03em',
  },
  h6: {
    fontSize: '0.875rem', // 14px
    fontWeight: 400,
    lineHeight: '1.625rem', // 26px
    letterSpacing: '-0.03em',
  },
  subtitle1: {
    fontSize: '1.125rem', // 18px
    fontWeight: 400,
    lineHeight: '2.75rem', // 44px
    letterSpacing: '-0.03em',
  },
  subtitle2: {
    fontSize: '1.125rem', // 18px
    fontWeight: 500,
    lineHeight: '2.75rem', // 44px
    letterSpacing: '-0.03em',
  },
  body1: {
    fontSize: '0.875rem', // 14px
    fontWeight: 400,
    lineHeight: '1.625rem', // 26px
    letterSpacing: '-0.03em',
  },
  body2: {
    fontSize: '0.875rem', // 14px
    fontWeight: 500,
    lineHeight: '1.5rem', // 24px
    letterSpacing: '-0.03em',
  },
  caption: {
    fontSize: '0.75rem', // 12px
    fontWeight: 400,
    lineHeight: '1.375rem', // 22px
    letterSpacing: '-0.03em',
  },
  button: {
    fontSize: '1.125rem', // 18px
    fontWeight: 500, // Medium
    // lineHeight: '2.75rem', // 44px
    letterSpacing: '-0.03em',
    textTransform: 'none'
  },
  overline: {
    fontSize: '0.625rem', // 10px
    fontWeight: 400,
    lineHeight: '1.125rem', // 18px
    letterSpacing: '-0.03em',
    textTransform: 'none',
  },
  // Custom typography variants
  TitleL: {
    fontSize: '1.75rem', // 28px
    fontWeight: 400, // Regular
    lineHeight: '3rem', // 48px
    letterSpacing: '-0.03em',
  },
  TitleL_B: {
    fontSize: '1.75rem', // 28px
    fontWeight: 500, // Medium
    lineHeight: '3rem', // 48px
    letterSpacing: '-0.03em',
  },
  TitleM: {
    fontSize: '1.5rem', // 24px
    fontWeight: 400, // Regular
    lineHeight: '2.5rem', // 40px
    letterSpacing: '-0.03em',
  },
  TitleM_B: {
    fontSize: '1.5rem', // 24px
    fontWeight: 500, // Medium
    lineHeight: '2.5rem', // 40px
    letterSpacing: '-0.03em',
  },
  TitleS: {
    fontSize: '1.25rem', // 20px
    fontWeight: 400, // Regular
    lineHeight: '2.125rem', // 34px
    letterSpacing: '-0.03em',
  },
  TitleS_B: {
    fontSize: '1.25rem', // 20px
    fontWeight: 500, // Medium
    lineHeight: '2.125rem', // 34px
    letterSpacing: '-0.03em',
  },
  BaseL: {
    fontSize: '1.125rem', // 18px
    fontWeight: 400, // Regular
    lineHeight: '1.875rem', // 30px
    letterSpacing: '-0.03em',
  },
  BaseL_B: {
    fontSize: '1.125rem', // 18px
    fontWeight: 500, // Medium
    lineHeight: '1.875rem', // 30px
    letterSpacing: '-0.03em',
  },
  BaseM: {
    fontSize: '1rem', // 16px
    fontWeight: 400, // Regular
    lineHeight: '1.75rem', // 28px
    letterSpacing: '-0.03em',
  },
  BaseM_B: {
    fontSize: '1rem', // 18px
    fontWeight: 500, // Medium
    lineHeight: '1.75rem', // 44px
    letterSpacing: '-0.03em',
  },
  BaseS: {
    fontSize: '0.875rem', // 14px
    fontWeight: 400, // Regular
    lineHeight: '1.625rem', // 26px
    letterSpacing: '-0.03em',
  },
  BaseS_B: {
    fontSize: '0.875rem', // 14px
    fontWeight: 500, // Medium
    lineHeight: '1.5rem', // 24px
    letterSpacing: '-0.03em',
  },
  FootNote: {
    fontSize: '0.75rem', // 12px
    fontWeight: 400, // Regular
    lineHeight: '1.375rem', // 22px
    letterSpacing: '-0.03em',
  },
  FootNote_B: {
    fontSize: '0.75rem', // 12px
    fontWeight: 500, // Medium
    lineHeight: '1.375rem', // 22px
    letterSpacing: '-0.03em',
  },
  Caption_B: {
    fontSize: '0.625rem', // 10px
    fontWeight: 500, // Medium
    lineHeight: '1.125rem', // 18px
    letterSpacing: '-0.03em',
  },
  Caption: {
    fontSize: '0.625rem', // 10px
    fontWeight: 400, // Regular
    lineHeight: '1.125rem', // 18px
    letterSpacing: '-0.03em',
  },
};

export const shadows: Shadows = [
  'none',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
]

export const shape = {
  borderRadius: 12,
}


export const components: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline:{
    styleOverrides:`
        @font-face {
          font-family: 'SBAggro';
          font-style: normal;
          font-weight: 300;
          src: url('/fonts/SBAggroL.ttf') format('truetype'),
               url('/fonts/SBAggroL.otf') format('opentype');
        }
        @font-face {
          font-family: 'SBAggro';
          font-style: normal;
          font-weight: 500;
          src: url('/fonts/SBAggroM.ttf') format('truetype'),
               url('/fonts/SBAggroM.otf') format('opentype');
        }
        @font-face {
          font-family: 'SBAggro';
          font-style: normal;
          font-weight: 700;
          src: url('/fonts/SBAggroB.ttf') format('truetype'),
               url('/fonts/SBAggroB.otf') format('opentype');
        }
    `
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        background: 'transparent',
        boxShadow: 'none',
      },
    }
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: '52px',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 20,
        paddingRight: 20,
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '12px',
      }
    }
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '12px',
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 16,
        paddingBottom: 16,
        '&:last-child': {
          paddingBottom: 12,
        }
      }
    }
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        padding: 0,
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: () => ({
        transform: 'translate(8px, -20px) scale(1)'
      })
    }
  },
  MuiTypography: {},
  MuiTabs: {
    styleOverrides: {
      root: ({theme}) => ({
        '& .MuiTabs-indicator': {
          color: theme.palette.typography.primary,
          backgroundColor: theme.palette.typography.primary
        }
      })
    }
  },

  MuiTab: {
    styleOverrides: {
      root: ({theme}) => ({
        ...theme.typography.BaseM_B,
        color: theme.palette.typography.tertiary,
        minWidth: 0,
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '10px',
        paddingBottom: '10px',
        '&.Mui-selected': {
          color: theme.palette.typography.primary
        },
      })
    }
  },
  MuiTable: {},
  MuiTableCell: {
    styleOverrides: {
      root: () => ({
        borderBottom: 'none',
      })
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({theme}) => ({
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '12px',
        paddingRight: '12px',
        borderRadius: 8,
        'label + &': {
          marginTop: 8
        },
        '&.Mui-disabled': {
          borderColor: theme.palette.divider,
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.divider,
        },
        '& .MuiInputBase-input': {
          padding: 0,
          caretColor: theme.palette.primary.main,
        },
        '& .MuiIcon-root': {
          fontSize: '0.75rem'
        },
        ...theme.typography.BaseM
      }),
      input: {
        height: '1.75rem',
      },

      notchedOutline: ({theme}) => ({
        borderColor: theme.palette.divider,
        borderWidth: '1px !important',
        top: 0,
        '& legend span': {
          display: 'none'
        },

      })

    }

  },
  MuiBottomNavigationAction:{
    styleOverrides:{
      root: ({ theme }) => ({
        minWidth: 0,
        minHeight: 0,
        paddingTop:'8px',
        paddingBottom:'8px',
        color:theme.palette.typography.tertiary,
        '&.Mui-selected': {
          color:theme.palette.typography.primary,
        }
      }),
      label:({theme})=>({
        height:'14px',
        ...theme.typography.FootNote,
        lineHeight:1,
        marginTop: '2px',
        '&.Mui-selected': {
          ...theme.typography.FootNote,
          lineHeight:1,
        }
      })
    }
  },
  MuiInputBase: {
    styleOverrides: {
      input: ({theme}) => ({
        caretColor: theme.palette.primary.main,
      }),
    }
  },
  MuiSelect: {
    styleOverrides: {
      root: ({theme}) => ({
        color: theme.palette.typography.tertiary,
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '8px',
        paddingRight: '8px',
        borderRadius: '6px',
        ...theme.typography.BaseM
      }),
      select: {
        '&.MuiInputBase-input': {
          paddingLeft: '4px',
          paddingRight: '24px',
        },
      },
      icon: ({theme}) => ({
        color: theme.palette.typography.tertiary,
        fontSize: '1rem'
      })
    }
  },


  MuiButton: {
    styleOverrides: {
      root: () => ({
        boxShadow: 'none',
        textTransform: 'none',
      }),
      sizeLarge: ({theme}) => ({
        borderRadius: 12,
        padding: 14,
        ...theme.typography.BaseM_B
      }),

      sizeMedium: ({theme}) => ({
        padding: 12,
        borderRadius: '8px',
        ...theme.typography.BaseS_B
      }),

      sizeSmall: ({theme}) => ({
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '8px',
        paddingRight: '8px',
        borderRadius: '8px',
        ...theme.typography.FootNote_B
      }),

      contained: ({theme, ownerState}) => ({
        color: ownerState.color === 'fill' ? theme.palette.typography.primary
            : theme.palette[ownerState.color as keyof typeof variantColors].contrastText,
        backgroundColor: ownerState.color === 'fill' ? theme.palette.fill.primary
            : theme.palette[ownerState.color as keyof typeof variantColors].main,
        '&.Mui-disabled': {
          opacity: '0.4',
          color: ownerState.color === 'fill' ? theme.palette.typography.primary
              : theme.palette[ownerState.color as keyof typeof variantColors].contrastText,
          backgroundColor: ownerState.color === 'fill' ? theme.palette.fill.primary
              : theme.palette[ownerState.color as keyof typeof variantColors].main,
        }
      }),
      outlined: ({theme, ownerState}) => ({
        color: ownerState.color === 'fill' ? theme.palette.fill.primary
          : theme.palette[ownerState.color as keyof typeof variantColors].main,
        '&.Mui-disabled': {
          opacity: '0.4',
          color: ownerState.color === 'fill' ? theme.palette.fill.primary
            : theme.palette[ownerState.color as keyof typeof variantColors].main,
        }
      }),
      startIcon: {
        margin: 0,
        '& .MuiSvgIcon-root': {
          width: '1.2em',
          height: '1.2em'
        }
      },

      endIcon: {
        margin: 0,
        '& .MuiSvgIcon-root': {
          width: '1.2em',
          height: '1.2em'
        }
      }
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: '0px'
      },
      sizeLarge: () => ({
        '& .MuiIcon-root': {},
        ...theme.typography.TitleS
      }),

      sizeMedium: () => ({
        '& .MuiIcon-root': {},
        ...theme.typography.BaseL
      }),

      sizeSmall: ({theme}) => ({
        '& .MuiIcon-root': {},
        ...theme.typography.BaseM
      }),
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({theme}) => ({
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '6px',
        paddingRight: '6px',
        color: theme.palette.typography.tertiary,
        borderColor: theme.palette.divider,
        borderRadius: '6px',
        backgroundColor: 'none',
        '&.Mui-selected': {
          color: theme.palette.primary.main,
          borderColor: 'currentColor',
          background: 'none',
          backgroundColor: 'none',
          // '&:hover':{
          //   background:'none'
          // }
        },
      })
    }
  },
  MuiCheckbox: {
    styleOverrides: {}
  },
  MuiAlert: {
    styleOverrides: {
      root: ({theme, ownerState}) => ({
        backgroundColor: `color-mix(in srgb, ${theme.palette[ownerState.severity ?? 'info'].main} 75%, transparent)`,
        color: theme.palette[ownerState.severity ?? 'info'].contrastText,
        '& .MuiAlert-icon': {
          color: theme.palette[ownerState.severity ?? 'info'].contrastText,
        }
      })
    }
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        display: 'inline-flex',
        maxWidth: 'calc(100vw - 40px)',
        margin: 'auto',
      }
    }
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: ({theme}) => ({
        display: 'inline-flex',
        flexGrow: 0,
        backgroundColor: theme.palette.scrim.primary,
        justifyContent: 'center',
        ...theme.typography.BaseM_B
      })
    }
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: () => ({
        minWidth: 0,
      })
    }
  },
  MuiListItemButton: {
    styleOverrides: {
      root: () => ({}),
      disabled: () => ({
        opacity: 0.4
      })
    }
  },
  MuiModal: {
    styleOverrides: {
      root: {
        '& .MuiBackdrop-root': {
          transition: 'none !important'
        },
      }
    }
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ownerState, theme}) => ({
        height: '6px',
        borderRadius: '6px',
        backgroundColor: ownerState.color === 'primary' ? theme.palette.fill.primary : theme.palette[ownerState.color as keyof typeof variantColors].light
      })
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& *::placeholder': {
          opacity: 0.3
        }
      }
    }
  }
}


export const theme = createTheme({
  components: components,
  palette: palette,
  typography: typography,
  shape: shape,
  shadows: shadows
});

