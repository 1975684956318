import { GlobalStyles } from '@mui/material';


const GlobalCSS = () => (
  <GlobalStyles
    styles={{
      'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video': {
        margin: 0,
        padding: 0,
        border: 0,
        fontSize: '100%',
        font: 'inherit',
        verticalAlign: 'baseline',
        scrollbarWidth: 'none', /* Firefox */
        '&:-webkit-scrollbar': {
          display: 'none',
        },
      },
      /* HTML5 display-role reset for older browsers */
      'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section': {
        display: 'block',
      },
      body: {
        lineHeight: 1,
      },
      'ol, ul': {
        listStyle: 'none',
      },
      'blockquote, q': {
        quotes: 'none',
      },
      'blockquote:before, blockquote:after, q:before, q:after': {
        content: 'none',
      },
      table: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
      },


      '@keyframes color-change-primary': {
        light: '',
        '0%': {
          color: '#379DFF',
        },
        '50%': {
          color: '#379DFF',
        },
        '1000%': {
          color: '#379DFF',
        },
      },

      '@keyframes fly': {
        '0%': {
          transform: 'translate(50px, 50px) rotate(0deg)',
          opacity: 0,
        },
        '100%': {
          transform: 'translate(0, 0) rotate(0deg)',
          opacity: 1,
        },
      },

      '@keyframes vibration': {
        '0%': {
          transform: 'translateY(0)',
        },
        '25%': {
          transform: 'translateY(5px)',
        },
        '50%': {
          transform: 'translateY(-5px)',
        },
        '75%': {
          transform: 'translateY(5px)',
        },
        '100%': {
          transform: 'translateY(0)',
        },
      },

      '.naver-map-custom-marker': {
        position: 'relative',
        padding: '5px 10px',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '10px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
        fontWeight: 500,
      },

      /* 말풍선의 꼬리 부분 스타일 */
      '.naver-map-custom-marker::after': {
        content: '""',
        position: 'absolute',
        bottom: '-10px',
        left: '50%',
        marginLeft: '-10px',
        width: '0',
        height: '0',
        borderWidth: '10px 10px 0',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.5) transparent transparent transparent',
      },
    }}
  />
);

export default GlobalCSS;