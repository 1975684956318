import {useLayoutEffect, useState} from 'react';
import getTimeRemainUntilKST from '~/utils/getTimeRemainsUntilKST.ts';
import {useTranslation} from "react-i18next";

type TimeRemains = {
  hours: number,
  minutes: number,
  seconds: number,
  text: string,
}

type CutOffDay = string | number;

type TimeoutCutOff = {
  cutOffTime: number,
  timeUntilCutOff: TimeRemains,
  isPassedTodayCutOff: boolean,
  cutOffDay: CutOffDay,
}

type UseTimeoutCutOff = {
  timeoutCutOff: TimeoutCutOff,
}

type TimeoutCutOffProps = {
  cutOffTime: number,
  cutOffDate: Date,
}

const calcCutOff = (cutOffTime: number, dateLabels: { today: string, tomorrow: string }): TimeoutCutOff => {
  const {hours, seconds, minutes, isPassedToday,} = getTimeRemainUntilKST(cutOffTime);
  return {
    cutOffTime,
    timeUntilCutOff: {
      hours,
      minutes,
      seconds,
      text: `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
    },
    isPassedTodayCutOff: isPassedToday,
    cutOffDay: isPassedToday ? dateLabels.tomorrow : dateLabels.today
  }
};

export default function useTimeoutCutOff(props: TimeoutCutOffProps): UseTimeoutCutOff {
  const {cutOffTime} = props;
  const {i18n} = useTranslation();

  const dateLabels = {
    tomorrow: i18n.t('hook-useTimeoutCutOff-tomorrow'),
    today: i18n.t('hook-useTimeoutCutOff-today')
  };

  const [timeoutCutOff, setTimeoutCutOff] = useState<TimeoutCutOff>(calcCutOff(cutOffTime, dateLabels));

  useLayoutEffect(() => {
    const interval = () => {
      setTimeoutCutOff(calcCutOff(cutOffTime, dateLabels));
    }

    const intervalId: ReturnType<typeof setInterval> = setInterval(interval, 1000);
    return () => {
      clearInterval(intervalId)
    }
  }, [cutOffTime]);

  return {
    timeoutCutOff
  }
}
