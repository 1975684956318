import {useMemo, useState} from 'react';
import dayjs from 'dayjs';
import useSyncReservation from '~/pages/home/hooks/useSyncReservation.ts';
import Page from '~/components/Page';
import styled from '~/components/styled.tsx';
import Container from '~/components/Container.tsx';
import Typography from '~/components/Typography.tsx';
import Button from '~/components/Button';
import useKintStore from '~kint/store';
import Paper from '~/components/Paper';
import ChatListItem from '~/components/ChattingListItem.tsx';
import ProductList from '~/components/ProductList.tsx';
import useProducts from '~/hooks/useProducts.ts';
import ReservationPopup from '~/pages/home/partial/ReservationPopup.tsx';
import TourInfoSection from '~/pages/home/partial/TourInfoSection.tsx';
import {useLocation, useNavigate} from 'react-router-dom';
import Divider from '~/components/Divider.tsx';
import CommonFooter from '~/components/CommonFooter.tsx';
import {IChatting} from '~kint/models/Chatting.ts';
import useListenChattings from '~/pages/message/chat/hooks/useListenChattings.ts';
import Stack from '~/components/Stack.tsx';

import useScrollIn from '~/hooks/useScrollIn.ts';
import useDeliveryCutOff from '~/pages/shop/hooks/useDeliveryCutOff.ts';
import TagProductSection from '~/pages/home/components/TagProductSection.tsx';
import useTags from '~/hooks/useTags.ts';
import PromotionSection from '~/components/PromotionSection.tsx';
import usePromotions from '~/hooks/usePromotions.ts';
import {useTranslation} from "react-i18next";
import IconButton from "~/components/IconButton.tsx";
import useKupStore from "~kup/store";
// import TagProductSection from "~/pages/home/components/TagProductSection.tsx";

const GradientContainer = styled(Container)({
  background: 'linear-gradient(0deg, #FFF -32.32%, #E3EEFF 109.39%)',
});


export default function HomeMain() {
  useSyncReservation();
  const location = useLocation();
  const fromEntry = location?.state?.fromEntry;
  const navigate = useNavigate();
  const {i18n} = useTranslation();
  const {reservation, name} = useKintStore((state) => ({
    reservation: state.reservation, name: state.name,
  }));
  const {setAuthLocale} = useKupStore(state => ({setAuthLocale: state.setAuthLocale,}));

  const highlightTags = useTags(undefined, true);
  const {promotions} = usePromotions({isHighlight: true});
  const [openReservationPopup, setOpenReservationPopup] = useState<boolean>(false);
  const reservationDayjs = reservation ? dayjs(reservation.date + ' 09:00') : dayjs();
  const chattings = useListenChattings();
  const sortChats = (aChat: IChatting, bChat: IChatting) => {
    return (bChat.updatedAt ?? bChat.createdAt).toDate().getTime() - (aChat.updatedAt ?? aChat.createdAt).toDate().getTime();
  };
  const {products: frontProducts} = useProducts({category: 0, excludeSoldOut: true}, {
    startPage: 1,
    pageSize: 4,
    continuous: true
  });
  const {products, page, hasNextPage, next} = useProducts({category: 0, excludeSoldOut: true}, {
    startPage: 2,
    pageSize: 4,
    continuous: true,
  });

  const randomHighlightIndex = useMemo(() => Math.round(Math.random() * (highlightTags.length - 1)), [highlightTags.length]);
  const randomizeTag = highlightTags.at(randomHighlightIndex);

  const handleToggleOpenReservationPopup = () => {
    setOpenReservationPopup((prev) => !prev);
  };

  const handleTranslate = () => {
    if (i18n.language === 'en-US') {
      i18n.changeLanguage('zh-CN', () => {
        setAuthLocale('zh-CN')
      })
      return;
    }
    if (i18n.language === 'zh-CN') {
      i18n.changeLanguage('zh-TW', () => {
        setAuthLocale('zh-TW')
      })
      return;
    }
    if (i18n.language === 'zh-TW') {
      i18n.changeLanguage('en-US', () => {
        setAuthLocale('en-US')
      })
      return;
    }

    i18n.changeLanguage('en-US', () => {
      setAuthLocale('en-US')
    })
  }

  if (!reservation) return null;
  return (
    <>
      <Page
        type={'main'}
        backgroundColor={'paper'}
        navigationBarProps={{
          position: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          },
          trail: (
            <IconButton
              touchArea={'16px'}
              iconProps={{
                width: '24px'
              }}
              color={'typography.secondary'}
              onClick={handleTranslate}
            >
              {
                i18n.language === 'en-US'
                  ? 'english'
                  : i18n.language === 'zh-TW'
                    ? 'chinese_traditional'
                    : i18n.language === 'zh-CN'
                      ? 'chinese_simplified'
                      : 'translate'
              }
            </IconButton>
          )
        }}
      >
        <GradientContainer
          pt={'58px'}
          pb={'30px'}
          px={'20px'}
        >
          <Typography variant={'FootNote'} color={'typography.secondary'}>
            {reservationDayjs.format('YY.MM.DD')}
          </Typography>
          <Container
            stackProps={{
              row: true,
              gap: '4px',
              alignItems: 'flex-end',
            }}
          >
            <Container
              stackProps={{
                column: true,
              }}
            >
              <Typography variant={'BaseL_B'} component={'p'}>
                {i18n.t('home-main-hiUser', {name})}
              </Typography>
              <Typography variant={'BaseL_B'} component={'p'}>
                {
                  fromEntry
                    ? i18n.t('home-main-joinTourChat')
                    : reservationDayjs.diff(dayjs(), 'hour') > 0
                      ? i18n.t('home-main-cantWait')
                      : reservationDayjs.diff(dayjs(), 'hour') < -8
                        ? i18n.t('home-main-hopeEnjoyedTour')
                        : i18n.t('home-main-enjoyTour')
                }
              </Typography>
            </Container>
            <Button
              size={'small'}
              sx={{
                flexShrink: 0,
              }}
              onClick={handleToggleOpenReservationPopup}
            >
              {i18n.t('home-main-myBooking')}
            </Button>
          </Container>
          <Container
            pt={'16px'}
            stackProps={{
              p: 0,
              gap: '8px',
            }}
          >
            {chattings.sort(sortChats).map((chat) => (
              <Paper
                key={chat?.id}
                sx={{
                  animation: fromEntry ? '0.75s 3 vibration' : undefined,
                  p: 0,
                }}
              >
                {/*  최초 진입할 경우 모든 메세지를 보여줄 수 있음 */}
                <ChatListItem key={chat?.lastMessage?.id} chat={chat}/>
              </Paper>
            ))}
          </Container>
        </GradientContainer>
        <Container
          px={'20px'}
          py={'20px'}
        >
          <TourInfoSection/>
        </Container>

        <Container
          px={'20px'}
          py={'20px'}
        >
          <Banner/>
        </Container>

        {
          (promotions ?? []).map((promotion) => (
            <PromotionSection key={promotion.id} promotion={promotion}/>
          ))
        }
        {randomizeTag &&
            <Container
                py={'20px'}
                stackProps={{
                  column: true,
                  gap: '12px',
                }}
            >
              {
                [randomizeTag]
                  .map((tag) => {
                    if (tag === undefined) return null;
                    return (
                      <TagProductSection key={tag.id} tag={tag}/>
                    );
                  })
              }
            </Container>
        }

        <Container
          py={'12px'}
        >
          <ProductList type={'pair'}
                       title={i18n.t('home-main-grabBestPrice')}
                       products={frontProducts}
          />

          <ProductList type={'pair'}
                       products={products}
                       onInfiniteScroll={hasNextPage && page < 7 ? () => next() : undefined}
                       onClickLinkMore={() => navigate('/shop')}
          />
        </Container>
        <Divider color={'fill'} type={'box'}/>
        <CommonFooter/>
      </Page>
      <ReservationPopup
        open={openReservationPopup}
        onClose={handleToggleOpenReservationPopup}
      />
    </>
  );
}


function Banner() {
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const bannerRef = useScrollIn(() => setIsVisible(true), () => setIsVisible(false), 1);
  const {deliveryCutOff} = useDeliveryCutOff();
  const {deliveryDay} = deliveryCutOff;
  return (
    <>
      <Button fullWidth color={'fill'} onClick={() => navigate('/shop')}>
        <Stack row alignItems={'flex-end'} sx={{width: '100%'}}>

          <Stack
            justifyContent={'flex-start'}
            textAlign={'left'}
            sx={{
              width: '100%',
            }}
          >
            <Typography variant={'Caption'} color={'typography.secondary'}>
              {i18n.t('home-main-bestServiceVibe')}
            </Typography>
            <Typography color={'typography.primary'} variant={'BaseS_B'} textAlign={'left'} whiteSpace={'pre-wrap'}
                        sx={{flexGrow: 1}}>
              {i18n.t('home-main-blazingFastDelivery', {deliveryDay: deliveryDay?.toUpperCase()})}
            </Typography>
          </Stack>
          <img
            src={'/rocket.png'} width={'64px'}
            style={{
              flexGrow: 1,
              animation: isVisible ? '1s fly' : undefined,
              position: 'relative',
            }}/>
        </Stack>
      </Button>
      <div ref={bannerRef} style={{position: 'relative', bottom: -120}}/>
    </>
  );
}
