import {useLayoutEffect, useState} from "react";
import getTimeRemainUntilKST from "~/utils/getTimeRemainsUntilKST.ts";
import useKupStore from "~kup/store";
import {useTranslation} from "react-i18next";


type TimeRemains = {
  hours: number,
  minutes: number,
  seconds: number,
  text: string,
}

type DeliveryDay = string;

type DeliveryCutOff = {
  cutOffTime: number,
  timeUntilCutOff: TimeRemains,
  isPassedTodayCutOff: boolean,
  deliveryDay: DeliveryDay,
}

type UseDeliveryCutOff = {
  deliveryCutOff: DeliveryCutOff,
}

const calcCutOff = (cutOffTime: number, today: string, tomorrow: string): DeliveryCutOff => {
  const {hours, seconds, minutes, isPassedToday,} = getTimeRemainUntilKST(cutOffTime);
  return {
    cutOffTime,
    timeUntilCutOff: {
      hours,
      minutes,
      seconds,
      text: `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
    },
    isPassedTodayCutOff: isPassedToday,
    deliveryDay: isPassedToday ? tomorrow : today
  }
};

export default function useDeliveryCutOff(): UseDeliveryCutOff {
  const {cutOffTime} = useKupStore((state) => ({cutOffTime: state.deliveryCutOff}));
  const {i18n} = useTranslation();
  const [deliveryCutOff, setDeliveryCutOff] = useState<DeliveryCutOff>(
    calcCutOff(cutOffTime,
      i18n.t('shop-constants-today') // today
      , i18n.t('shop-constants-tomorrow') // tomorrow
    )
  );

  useLayoutEffect(() => {
    const interval = () => {
      setDeliveryCutOff(
        calcCutOff(cutOffTime,
          i18n.t('shop-constants-today') // today
          , i18n.t('shop-constants-tomorrow') // tomorrow
        )
      );
    }

    const intervalId: ReturnType<typeof setInterval> = setInterval(interval, 1000);
    return () => {
      clearInterval(intervalId)
    }
  }, [cutOffTime]);

  return {
    deliveryCutOff
  }
}
