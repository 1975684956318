export interface ReservationBase {
  id: string;
  agency: string;
  agencyCode: string;
  language: string;
  clientName: string;
  email: string,
  date: string;
  product: string;
  adult: number;
  kid: number;
  infant: number;
  pickupPlace: string;
  canceledAt?: string;
  teamId: string;
  productId: string;
  nationality?: string;
  pickupInfo?: Partial<ReservationPickupInfoBase>;
}

export interface ReservationPickupInfoBase {
  cn: string,
  cnImage: string,
  en: string,
  enImage: string,
  ko: string,
  lat: number,
  lng: number,
  place: string,
  time: string,
}

export class ReservationPickupInfo implements ReservationPickupInfoBase {
  cn: string;
  cnImage: string;
  en: string;
  enImage: string;
  ko: string;
  lat: number;
  lng: number;
  place: string;
  time: string;

  constructor(raw: Partial<ReservationPickupInfoBase>) {
    this.cn = raw.cn ?? '';
    this.cnImage = raw.cnImage ?? '';
    this.en = raw.en ?? '';
    this.enImage = raw.enImage ?? '';
    this.ko = raw.ko ?? '';
    this.lat = raw.lat ?? 0;
    this.lng = raw.lng ?? 0;
    this.place = raw.place ?? '';
    this.time = raw.time ?? '';
  }
}


export default class Reservation implements ReservationBase {
  id: string;
  agency: string;
  agencyCode: string;
  language: string;
  clientName: string;
  date: string;
  product: string;
  adult: number;
  kid: number;
  infant: number;
  email: string;
  teamId: string;
  productId: string;
  pickupPlace: string;
  canceledAt?: string;
  nationality?:string;
  pickupInfo?: Partial<ReservationPickupInfo>;

  constructor(raw: ReservationBase) {
    this.id = raw.id;
    this.agency = raw.agency;
    this.agencyCode = raw.agencyCode;
    this.language = raw.language;
    this.clientName = raw.clientName;
    this.email = raw.email;
    this.date = raw.date;
    this.product = raw.product;
    this.adult = raw.adult;
    this.kid = raw.kid;
    this.infant = raw.infant;
    this.teamId = raw.teamId;
    this.productId = raw.productId;
    this.pickupPlace = raw.pickupPlace;
    this.canceledAt = raw.canceledAt;
    this.nationality = raw.nationality;
    this.pickupInfo = raw.pickupInfo ? new ReservationPickupInfo(raw.pickupInfo) : undefined;
  }

  get departureDate(): Date {
    const time = this.pickupInfo?.time ?? '10:00';
    const date = this.date;
    return new Date(`${date}T${time}+09:00`);
  }

}
