import {ReactNode, useCallback, useEffect} from "react";
import {Navigate, Outlet, ScrollRestoration, useLocation, useNavigate} from "react-router-dom";
import useKintStore from "~kint/store";
import {useTranslation} from "react-i18next";
import {useSnackBar} from "~/contexts/SnackBarContext.tsx";
import Button from "~/components/Button.tsx";
import useListenRouterPath from "~/hooks/useListenRouterPath.ts";
import useKupStore from "~kup/store";

export default function BaseLayout({children, restoreScroll = true, hashPages}: {
  children?: ReactNode,
  restoreScroll?: boolean,
  hashPages?: (hash: string) => ReactNode | undefined
}) {

  const navigate = useNavigate();
  const location = useLocation();
  const {showSnackBar} = useSnackBar();

  const {reservationKey, reservation, participant} = useKintStore((state) => ({
    reservation: state.reservation,
    reservationKey: state.reservationKey,
    participant: state.participant,
  }));

  const {auth} = useKupStore((state)=>({
    auth: state.auth,
  }))

  useListenRouterPath();

  //Notification
  const chatNotificationClickHandler = useCallback(
    (event: ServiceWorkerContainerEventMap['message']) => {
      switch (event.data.action) {
        case 'notification-click':
          window.focus()
          if ('navigate' in window && typeof window.navigate === 'function') {
            window.navigate(event.data.url);
          } else {
            window.location.href = event.data.url;
          }
          return
      }
    },
    [window]
  )

  const showNotificationSnackbar = useCallback((messageEvent: MessageEvent) => {
    try {
      const data = JSON.parse(messageEvent.data);
      if (data.type === 'FCM_FORE_MESSAGE') {
        const payload = data.payload;
        const message = payload.message;
        const messageData = message?.data;
        if (!messageData) return;
        // {"body": "asdf", "chatId": "09b5e05b-c78c-4efd-a610-30b3f6fd358c", "name": "Dodo(dev-op)", "slug": "kint-chat",
        const {body, chatId, name, slug,} = messageData;
        if (slug !== 'kint-chat') return;
        if (location.pathname.includes(`/message/chatting/${chatId}`)) return;
        showSnackBar({
          message: `${name} - ${body}`,
          lift: true,
          action: <Button variant={'text'} size={'small'} onClick={() => navigate(`/message/chatting/${chatId}`)}>Check
            It</Button>
        });

      }
    } catch (e) {
      console.error(e);
    }
  }, [showSnackBar,]) //`/message/chatting/${chatId}`

  useEffect(() => {
    if (window && window.navigator && navigator.serviceWorker) {
      window.navigator.serviceWorker.addEventListener("message", chatNotificationClickHandler)
    }

    if (window && window.ReactNativeWebView) {
      window.addEventListener('message', showNotificationSnackbar, {capture: true});
    }

    return () => {
      if (window && window.navigator && navigator.serviceWorker) {
        window.navigator.serviceWorker.removeEventListener("message", chatNotificationClickHandler)
      }

      if (window && window.ReactNativeWebView) {
        window.removeEventListener('message', showNotificationSnackbar, {capture: true});
      }
    }
  }, [chatNotificationClickHandler])


  //i18n
  const {i18n} = useTranslation();
  useEffect(() => {
    if(auth?.locale){
      i18n.changeLanguage(auth.locale)
        .catch((error) => console.error('Language change error:', error));
    }else{
      const nationality = (reservation?.nationality?.toLowerCase() ?? '').replace(/\s/gi, '');
      const language = reservation?.language?.toLowerCase() ?? 'english';
      const mappedLanguage = language === 'chinese'
        ? nationality.includes('hong kong') || nationality.includes('hongkong') || nationality.includes('taiwan')
          ? 'zh-TW'
          : 'zh-CN'
        : 'en-US';
      i18n.changeLanguage(mappedLanguage)
        .catch((error) => console.error('Language change error:', error));
    }
  }, [reservation?.language, i18n, auth?.locale]);


  //정보가 없는 상태에서 진입 방지
  const isReservationPage = location.pathname.startsWith('/reservation');
  const noReservation = !reservationKey || !reservation;
  const differentReservation = reservation?.agencyCode?.toLowerCase() !== reservationKey?.toLowerCase() && reservation?.id?.toLowerCase() !== reservationKey?.toLowerCase();
  const hasNoTourChat = participant?.reservationId !== reservation?.id;
  const hasNoReservation = noReservation || differentReservation;

  const isNotValidApproach = (hasNoReservation || hasNoTourChat) && !isReservationPage;


  if (isNotValidApproach) {
    navigate('/reservation', {replace: true});
    return null;
  }

  const hasPage = hashPages ? hashPages(location.hash) : undefined;


  return (
    <>
      {
        isNotValidApproach
          ? <Navigate to={'/reservation'} replace/>
          : hasPage ?? children ?? <Outlet/>
      }
      {
        restoreScroll && <ScrollRestoration/>
      }
    </>
  )
}
