import {KeyboardEventHandler, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import useKintStore from "~kint/store";
import {useChattingContext} from "~/pages/message/contexts/ChattingContext.tsx";

import {IChatting} from "~kint/models/Chatting.ts";

import Box from '~/components/Box';
import Container from "~/components/Container.tsx";
import InputBase from '~/components/InputBase';
import sendMessage from "~kint/controllers/sendMessage.ts";
import ImageUploadButton from "~/components/ImageUploadButton.tsx";
import {useSnackBar} from "~/contexts/SnackBarContext.tsx";
import {uploadFileToStorage} from "~kint/controllers/uploadFile.ts";
import Typography from "~/components/Typography.tsx";
import ButtonBase from '~/components/ButtonBase';
import Icon from '~/components/Icon';
import IconButton from '~/components/IconButton';
import {useTranslation} from "react-i18next";

export type MessageFormProps = {
  chatting: IChatting,
}

export default function MessageForm(props: MessageFormProps) {
  const {chatting} = props;
  const {i18n} = useTranslation();
  const {reservation, participant, name} = useKintStore((state) => ({
    reservation: state.reservation,
    participant: state.participant,
    name: state.name
  }))
  const {replyTo, clear} = useChattingContext();
  const {showSnackBar} = useSnackBar();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [inputMessage, setInputMessage] = useState<string>('');
  const [uploadImages, setUploadImages] = useState<Blob[] | null>(null);
  const [uploadKey, setUploadKey] = useState<string>(Date.now() + '');
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);

  const disableSend = !inputMessage && (!uploadImages || uploadImages.length === 0) && isSending;

  useEffect(() => {
    if (uploadError) {
      showSnackBar({message: 'Error occurs. Please retry it.', lift: true})
    }
  }, [uploadError])


  function handleClearReply() {
    clear();
  }

  async function onSendMessage() {
    if (isSending) return
    if (!participant || !reservation || !chatting) return
    setIsSending(true)
    if (inputMessage) {
      await sendMessage(
        chatting.id,
        {
          type: replyTo ? "reply" : "text",
          text: inputMessage,
        },
        participant,
        replyTo ? replyTo : undefined
      )
      handleClearReply();
      setInputMessage('');
    }


    if (uploadImages && uploadImages.length > 0) {
      const fileUrls = await Promise.all(uploadImages.map((image, idx) => {
        const onProgress = (newProgress: number) => {
          setUploadProgress((progresses) => {
            const newProgresses = [...progresses]
            newProgresses[idx] = newProgress
            return newProgresses
          });
        };
        return uploadFileToStorage(`chats/${chatting.id}/${uuidv4()}`, image, onProgress);
      }))

      await sendMessage(
        chatting.id,
        {
          type: "image",
          files: fileUrls,
        },
        participant,
      )

      setUploadProgress([]);
      setUploadImages(null);
      setUploadKey(Date.now() + '');
    }
    setIsSending(false)
  }

  const checkKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = async (event) => {
    const userAgent = window.navigator.userAgent
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
    if (
      !mobile &&
      !isSending &&
      event.key === "Enter" &&
      !event.nativeEvent.isComposing &&
      !event.shiftKey
    ) {
      event.preventDefault()
      await onSendMessage()
      return
    }
  }

  const handleImageUpload = (images: Blob[] | null) => {
    setUploadImages(images);
    setUploadKey(Date.now() + '')
  }

  const handleImageUploadError = (e: Error) => {
    setUploadError(e);
  }

  return (
    <Container
      bottom
      background={'paper'}
      stackProps={{
        column: true,
        gap: '8px'
      }}
    >
      {replyTo && (
        <Container
          px={'20px'}
          py={'4px'}
          stackProps={{
            column: true,
          }}
        >
          <Typography variant={'Caption'} flex={1} color={'typography.quaternary'}>
            {
              i18n.t('message-chat-replyToSender', {senderName: replyTo.sender.name}) // Reply to {replyTo.sender.name}
            }
          </Typography>
          <Container
            stackProps={{
              row: true,
              gap: '14px',
              alignItems: 'center'
            }}
          >
            <Typography variant={'FootNote'} flex={1} color={'typography.tertiary'} overflow={'hidden'}
                        textOverflow={'ellipsis'} sx={{maxLines: 1}} component={'p'} whiteSpace={'nowrap'}>
              {(replyTo.files?.length ?? 0) > 0 ? 'image' : replyTo.text ?? ''}
            </Typography>
            <IconButton onClick={handleClearReply} sx={{flex: 0}} color={'typography.tertiary'}
                        iconProps={{width: '20px'}}>
              close
            </IconButton>
          </Container>
        </Container>
      )}

      <Container
        pl={'8px'}
        stackProps={{
          display: 'flex',
          flexDirection: 'row',
          gap: '14px',
          alignItems: 'stretch',
          py: '0px',
        }}
      >
        <ImageUploadButton
          key={uploadKey}
          disabled={isSending}
          id={`${chatting.tour}${reservation?.id}${name}image`}
          onChange={handleImageUpload}
          onError={handleImageUploadError}
        />
        <InputBase
          fullWidth
          multiline
          autoFocus={false}
          maxRows={4}
          placeholder={
            i18n.t('message-chat-typeMessage') // Type message here
          }
          sx={(theme) => ({
            ...theme.typography.BaseS
          })}
          value={inputMessage}
          onChange={(e) => {
            setInputMessage(e.target.value)
          }}
          onKeyDown={checkKeyDown}
        />
        {
          !disableSend ?
            <ButtonBase
              disabled={disableSend}
              sx={theme => ({
                borderRadius: '0px',
                p: '12px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              })}
              onClick={onSendMessage}
            >
              <Icon
                width={'24px'}
              >
                send
              </Icon>
            </ButtonBase>
            : null
        }
      </Container>

      {uploadImages && uploadImages.length > 0 &&
        (

          <Box
            sx={{
              overflowX: 'scroll'
            }}
          >
            <Container
              px={'20px'}
              stackProps={{
                row: true,
                gap: '8px'
              }}
            >
              {uploadImages?.map((imageBlob, idx) => (
                <Box
                  key={'image' + idx}
                  sx={() => ({
                    position: "relative",
                  })}
                >
                  <img
                    src={URL.createObjectURL(imageBlob)}
                    style={{
                      width: "140px",
                      height: "140px",
                      objectFit: "cover",
                      borderRadius: '12px'
                    }}
                  />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      right: '4px',
                      top: '4px',
                      p: '2px',
                      background: 'white'
                    }}
                    onClick={() => {
                      setUploadImages((prev) => {
                        return prev?.filter(p => p !== imageBlob) ?? null
                      })
                    }}
                    iconProps={{width: '24px'}}
                  >
                    close
                  </IconButton>
                  {
                    uploadProgress[idx] !== undefined
                      ? <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'rgba(0,0,0,0.65)'
                      }}>
                        <Typography color={'white'}>
                          {Math.round(uploadProgress[idx])}%
                        </Typography>
                      </Box>
                      : null
                  }
                </Box>
              ))}
            </Container>
          </Box>
        )}
    </Container>
  )
}
