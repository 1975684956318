import {useTranslation} from "react-i18next";
import Box from '~/components/Box.tsx';
import Typography from '~/components/Typography.tsx';
import Badge from '~/components/Badge.tsx';
import useTimeoutCutOff from '~/hooks/useTimeoutCutOff.ts';


export default function PromotionTimeLimitBanner() {
  const { timeoutCutOff } = useTimeoutCutOff({ cutOffTime: 24, cutOffDate: new Date() });
  const {i18n} = useTranslation();
  return (
    <Box
      sx={(theme) => ({
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 900,
        backgroundColor:theme.palette.background.paper
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.palette.error.light,
          py: '8px',
          gap: '8px',
        })}
      >
        <Typography
          variant={"BaseS_B"}
          color={"error"}
        >
          {
            i18n.t('shop-product-availableDuringTour') // Available only during tour date!
          }
        </Typography>

        <Badge
          color={"error"}
        >
          {timeoutCutOff.timeUntilCutOff.text}
        </Badge>
      </Box>
    </Box>
  )
}
