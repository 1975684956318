import Dialog from "~/components/Dialog.tsx";
import Container from "~/components/Container.tsx";
import Typography from "~/components/Typography.tsx";
import useKintStore from "~kint/store";
import useChatNotificationToken from "~/hooks/useChatNotificationToken.ts";
import useKupNotificationToken from "~/hooks/useKupNotificationToken.ts";
import {useTranslation} from "react-i18next";


export default function TurnOnNotificationDialog(props: {
  open: boolean,
  onClose: () => void
}) {
  const {open, onClose} = props;
  const {i18n} = useTranslation();
  const {participant} = useKintStore((state) => ({
    participant: state.participant,
  }));

  const {requestToken, enableNotification} = useKupNotificationToken();
  const {requestToken: requestChatToken, enableChatNotification} = useChatNotificationToken();


  const handleTurnOn = () => {
    enableNotification();
    requestToken();

    if (participant) {
      enableChatNotification();
      requestChatToken();
    }
    onClose();
  }

  return (
      <Dialog
          title={i18n.t('message-chat-stayUpdatedImportantInfo')}
          open={open}
          onClose={onClose}
          onConfirm={handleTurnOn}
          confirm={
            i18n.t('message-chat-turnOn') // Turn On
          }
          onCancel={onClose}
          cancel={
            i18n.t('message-chat-close') // Close
          }
      >
        <Container
            px={'20px'}
            stackProps={{
              gap: '8px'
            }}
        >
          <Typography color={'typography.secondary'} whiteSpace={'pre-wrap'}>
            {
              i18n.t('message-chat-realtimeUpdatesInfo') // Get real-time updates. You can adjust your notification settings at any time.
            }<br/>
            {
              i18n.t('message-chat-skipNotificationFor12Hours') // If you skip, chat notification requests will be hidden for 12 hours.
            }<br/><br/>
            <strong>
              {
                i18n.t('message-chat-previouslyBlockedNotifications') // Previously blocked notifications for this device?
              }
            </strong><br/>
            {
              i18n.t('message-chat-changeSettingsForNotifications') // If you previously blocked notifications, you need to change the browser or app settings.
            }<br/>
            {
              i18n.t('message-chat-iosNotificationInstructions') // On iOS, go to Settings > Notifications > Kup and enable notifications.
            }<br/>
            {
              i18n.t('message-chat-androidNotificationInstructions') // On Android, go to Settings > Apps & notifications > Kup > Notifications and enable them.
            }<br/>
          </Typography>
        </Container>
      </Dialog>
  )
}
