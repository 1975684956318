import ModalPopup from "~/components/ModalPopup.tsx";
import Headline from "~/components/Headline.tsx";
import useDeliveryCutOff from "~/pages/shop/hooks/useDeliveryCutOff.ts";
import Container from "~/components/Container.tsx";
import FillBox from "~/components/FillBox.tsx";
import Button from "~/components/Button.tsx";
import {useNavigate} from "react-router-dom";
import useKupStore from "~kup/store";
import Typography from "~/components/Typography.tsx";
import {useTranslation} from "react-i18next";

export type OrderPopupProps = {
  open: boolean,
  onClose: () => void
}

export default function OrderPopup(props: OrderPopupProps) {
  const {open, onClose} = props;
  const {deliveryCutOff} = useDeliveryCutOff();
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const {setCheckOrderPopup} = useKupStore((state) => ({
    setCheckOrderPopup: state.setCheckOrderPopup
  }))
  const handleClickLater = () => {
    setCheckOrderPopup(true);
  }
  const handleClickCheck = () => {
    setCheckOrderPopup(true);
    navigate('/shop/address')
  }

  // todo 이후 다른 이벤트로 변경
  return null;

  return (
    <ModalPopup
      open={open}
      onClose={onClose}
      fixedBottom={
        <Container
          py={'8px'}
          px={'20px'}
          stackProps={{
            column: true,
            gap: '8px'
          }}
        >
          <Button
            fullWidth
            onClick={handleClickCheck}
          >
            {
              i18n.t('shop-main-checkHotelAvailability') // Check if your hotel is available
            }
          </Button>
          <Button
            fullWidth
            color={'fill'}
            onClick={handleClickLater}
          >
            {
              i18n.t('shop-main-checkLater') // Check Later
            }
          </Button>

        </Container>
      }
    >
      <Container
        px={'20px'}
        py={'16px'}
      >
        <Headline
          headline={
            i18n.t('shop-main-orderNowReceiveItByDeliveryDay', {deliveryDay: deliveryCutOff.deliveryDay.toUpperCase()}) // ORDER NOW!\nRECEIVE IT {deliveryCutOff.deliveryDay.toUpperCase()}
          }
          headlineProps={{whiteSpace: 'pre-wrap'}}
          description={
            i18n.t('shop-main-freeDeliveryOrderOver', {cutOffTime: deliveryCutOff.cutOffTime}) // Place an order over 100,000₩ before {deliveryCutOff.cutOffTime}pm\nand enjoy free delivery to your location by tonight!
          }
          descriptionProps={{whiteSpace: 'pre-wrap'}}
          size={'large'} textAlign={'center'}
        />
      </Container>
      <Container
        px={'20px'}
        py={'8px'}
      >
        <FillBox color={'fill'} innerBoxProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }
        }}>
          <img src={'/rocket.png'} width={'124px'}/>
          <Typography variant={'FootNote_B'} color={'typography.secondary'}>
            {
              i18n.t('shop-main-seoulDeliveryAvailable') // locations within Seoul are available for delivery
            }
          </Typography>
        </FillBox>

      </Container>

    </ModalPopup>
  )
}
