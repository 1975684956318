import {Navigate, useNavigate, useParams} from 'react-router-dom';
import Page from '~/components/Page';
import IconButton from '~/components/IconButton';
import Container from '~/components/Container.tsx';
import Headline from '~/components/Headline.tsx';
import List from '~/components/List';
import ListItem from '~/components/ListItem.tsx';
import Divider from '~/components/Divider.tsx';
import Icon from '~/components/Icon';
import useOrder from '~/pages/shop/hooks/useOrder.ts';
import NaverMap from '~/components/NaverMap.tsx';
import useKupStore from '~kup/store';
import Typography from '~/components/Typography.tsx';
import ValueList from '~/components/ValueList.tsx';
import Stack from '~/components/Stack.tsx';
import FillBox from '~/components/FillBox.tsx';
import Area from '~/components/Area.tsx';
import dayjs from 'dayjs';
import {OrderItem} from '~kup/models/Order.ts';
import ImageView from '~/components/ImageView.tsx';
import Badge from '~/components/Badge.tsx';
import CSPopup from '~/components/CSPopup.tsx';
import {ReactNode, useState} from 'react';
import CancelPopover from '~/pages/shop/myOrder/components/CancelPopover.tsx';
import {cancelOrder} from '~kup/controllers/order.ts';
import {ORDER_STATUS_GUIDANCE_MAP} from '~/pages/shop/constants';
import KupNotificationPermissionDialog from '~/pages/shop/myOrder/components/KupNotificationPermissionDialog.tsx';
import {useTranslation} from "react-i18next";


function OrderItemSummary({data}: { data: OrderItem }) {
  const {currency} = useKupStore((state) => ({currency: state.currency}));
  const selectedOptionNames = data.optionItemSelections.map((item) => item.name).join(' / ');

  return (
    <Container sx={{mb: 2}}>
      <Container py={'18px'}>
        <Stack
          row
          gap={'14px'}
          alignItems={'center'}
        >
          {
            data.productImageUrls.length > 0 &&
              <ImageView
                  src={data.productImageUrls[0]}
                  borderRadius={'6px'}
                  width={'60px'}
                  height={'60px'}
              />
          }
          <Headline
            sx={{flex: 1}}
            headline={data.productName}
            subHeadline={data.productBrand}
            headlineProps={{sx: {maxLines: 2, textOverflow: 'ellipsis'}}}
          />
        </Stack>
      </Container>
      <Container>
        <FillBox
          type={'border'}
          borderRadius={'6px'}
          trail={
            <Container stackProps={{column: true}}>
              <Typography variant={'BaseS_B'} whiteSpace={'nowrap'}>
                {((data.stock?.price ?? 0) * data.quantity).toLocaleString()} {currency}
              </Typography>
            </Container>
          }
        >
          <Typography
            variant={'FootNote'}
            color={'typography.secondary'}
            whiteSpace={'pre-wrap'}
          >
            {selectedOptionNames}, {data.quantity}ea
          </Typography>
        </FillBox>
      </Container>
    </Container>
  );
}

export default function ShopMyOrderPage() {
  const {orderId} = useParams();
  const navigate = useNavigate();
  const {i18n} = useTranslation();
  const {currency} = useKupStore((state) => ({
    currency: state.currency,
  }));
  const [openCS, setOpenCS] = useState<boolean>(false);
  const [openCancel, setOpenCancel] = useState<boolean>(false);

  const order = useOrder(orderId);


  const orderIsCancelable = order?.orderStatus && ['CONFIRMING_PAYMENT', 'CONFIRMING_ORDER', 'PREPARING_ITEMS', 'ASSIGN_DELIVERY'].includes(order.orderStatus);

  if (!order) return;
  if (!orderId) return <Navigate to={'/shop/orders'} replace/>;

  const {address, detail, request} = order.deliveryAddress;
  const {transactionStartedAt: orderedAt} = order.transaction;
  const {latitude: lat, longitude: lng} = address.location;

  const handleToggleCS = () => {
    setOpenCS((prev) => !prev);
  };
  const handleToggleCancel = () => {
    setOpenCancel((prev) => !prev);
  };

  const handleCancel = () => {
    cancelOrder(orderId)
      .then(window?.location?.reload)
      .catch((e) => {
        window?.location?.reload();
        console.error(e);
      });
  };

  const handleToGoAgreements = () => {
    navigate('/orderagreements');
  };

  const orderAmountPairInfos: { key: string, pair: [ReactNode, ReactNode] }[] = [
    {
      key: 'amountOfGoods',
      pair: [
        i18n.t('shop-myOrder-amountOfGoods') // Amount of goods
        ,
        (
          <Typography variant={'BaseS_B'} whiteSpace={'nowrap'}>
            {`${(order.orderItems.reduce(
                (acc, cur) => acc + cur.stock!.price, 0)
            ).toLocaleString()} ${currency}`}
          </Typography>
        ),
      ],
    },
    {
      key: 'deliveryCharge',
      pair: [
        i18n.t('shop-myOrder-deliveryCharge') // Delivery charge
        ,
        (
          <Stack column>
            <Typography variant={'BaseS_B'} whiteSpace={'nowrap'}>
              {`${order.deliveryFee.toLocaleString()} ${currency}`}
            </Typography>
          </Stack>
        ),
      ],
    },
  ];
  const appliedCoupon = order.transaction.appliedCoupon;
  if (appliedCoupon) {
    orderAmountPairInfos.push({
      key: 'appliedCoupon',
      pair: [
        i18n.t('shop-myOrder-appliedCoupon') // Applied coupon
        ,
        (
          <Stack column>
            <Typography variant={'BaseS_B'} whiteSpace={'nowrap'}>
              {`-${appliedCoupon.appliedDiscount.toLocaleString()} ${currency}`}
            </Typography>
            <Typography
              variant={'caption'}
              color={'typography.tertiary'}
              textAlign={'right'}
            >
              {
                i18n.t('shop-myOrder-appliedSpecificCoupon', {appliedCouponName: appliedCoupon.coupon.name}) // Applied {appliedCoupon.coupon.name}
              }
            </Typography>
          </Stack>
        ),
      ],
    });
  }

  return (
    <>
      <Page
        type={'modal'}
        name={orderId}
        navigationBarProps={{
          lead: (
            <>
              <IconButton
                size={'large'}
                iconProps={{
                  width: '24px',
                }}
                color={'typography.primary'}
                sx={(theme) => ({backgroundColor: theme.palette.background.paper, p: '4px'})}
                onClick={() => navigate('/shop/orders', {replace: true})}
              >
                close
              </IconButton>
            </>),
          background: 'transparent',
          position: {position: 'absolute', top: 0, left: 0, right: 0},
        }}
      >
        <Container style={{height: '30vh', width: '100vw', backgroundColor: 'grey'}}>
          <NaverMap
            placeName={order.deliveryAddress.address.displayName.text}
            center={{lat, lng}}
            containerStyles={{width: '100%', height: '30vh'}}
          />
        </Container>
        <Area
          background={'paper'}
          py={'8px'}
        >
          <Container
            py={'12px'}
            px={'20px'}
          >
            <Headline
              size={'large'}
              headline={ORDER_STATUS_GUIDANCE_MAP[order.orderStatus]?.title ? i18n.t(`shop-myOrder-${ORDER_STATUS_GUIDANCE_MAP[order.orderStatus]?.title}`) : '???'}
              description={
                i18n.t('shop-myOrder-deliveryFinishedBy10pm') // Delivery would be finished before 10pm today.
              }
            />
          </Container>
          <Area
            title={
              i18n.t('shop-myOrder-deliveryInfo') // Delivery Info
            }
            containerProps={{px: '20px', pb: '20px'}}
          >
            <ValueList
              pairs={[
                {
                  key: 'address',
                  pair: [
                    i18n.t('shop-myOrder-address') // Address
                    , address.formattedAddress]
                },
                {key: 'address_detail',
                  pair: [
                    i18n.t('shop-myOrder-addressDetail') // Address Detail
                    , detail]
                },
                {key: 'request', pair: [
                    i18n.t('shop-myOrder-request') // Request
                    , request]},
                {
                  key: 'contact',
                  pair: [
                    i18n.t('shop-myOrder-contact') // Contact,
                    ,<Stack row alignItems={'center'} gap={'8px'}>
                      <Icon width={'16px'}>{order.contact.channel}</Icon>
                      <Typography variant={'BaseM_B'} color={'typography.primary'}>
                        {order.contact.identifier}
                      </Typography>
                    </Stack>,
                  ],
                },
                {key: 'email', pair: [
                    i18n.t('shop-myOrder-email') // Email
                  , order.customerEmail]},
                {key: 'status', pair: [
                    i18n.t('shop-myOrder-status') // Status
                  , ORDER_STATUS_GUIDANCE_MAP[order.orderStatus]?.tag ?? '???']},
              ]}
            />
            <FillBox
              type={'border'}
              color={'fill'}
              py={'12px'}
              px={'16px'}
              lead={
                <Icon
                  width={'20px'}
                  type={'circle'}
                  size={'1.25em'}
                  color={'typography.tertiary'}
                >
                  info
                </Icon>
              }
            >
              <Typography variant={'FootNote_B'}>
                {
                  i18n.t('shop-myOrder-changeDestinationPrompt') // If you want to change the destination, Please cancel and re-order it.
                }
              </Typography>
            </FillBox>
          </Area>

          <Divider type={'box'} color={'fill'}/>

          <Area
            title={
              i18n.t('shop-myOrder-orderInfo') // Order Info
            }
            containerProps={
              {
                px: '20px',
                pb: '20px',
              }
            }
          >
            <ValueList
              pairs={[
                {key: 'customer', pair: [
                    i18n.t('shop-myOrder-customer') // Customer
                    , order.customerName]},
                {key: 'number', pair: [
                    i18n.t('shop-myOrder-orderNo') // Order No.
                  , order.orderNumber]},
                {
                  key: 'orderDate',
                  pair: [
                    i18n.t('shop-myOrder-orderDate') // Order Date
                    , `${dayjs(orderedAt).format('YY.MM.DD (ddd) HH:mm:ss ')} (KST)`,
                  ],
                },
              ]}
            />
          </Area>

          <Divider type={'box'} color={'fill'}/>

          <Area
            title={`${order.orderItems.length} Items`}
            containerProps={{px: '20px', pb: '20px'}}
          >
            {
              order.orderItems.map((orderItem, idx) => (
                <>
                  {idx > 0 && <Divider type={'line'} color={'fill'}/>}
                  <OrderItemSummary key={idx} data={orderItem}/>
                </>
              ))
            }
          </Area>

          <Divider type={'box'} color={'fill'}/>

          <Area
            title={(
              <Stack row alignItems={'center'} justifyContent={'space-between'}>
                {
                  order.transaction.status === 'PENDING' ?
                    (
                      <>
                        <Typography variant={'BaseM_B'}>To be paid</Typography>
                        <Badge color={'secondary'} size={'small'}>
                          {order.transaction.paymentMethod?.replace(/_/g, ' ') ?? ''}
                        </Badge>
                      </>
                    ) :
                    (
                      <Typography variant={'BaseM_B'}>
                        {
                          i18n.t('shop-myOrder-orderAmount') // Order Amount
                        }
                      </Typography>
                    )
                }
              </Stack>
            )}
            containerProps={{px: '20px', pb: '20px'}}
          >
            <ValueList
              rightCellAlign={'right'}
              pairs={orderAmountPairInfos}
            />
            <Divider type={'line'} color={'fill'}/>
            <Container
              pt={'12px'}
              stackProps={{row: true, justifyContent: 'space-between'}}
            >
              <Typography variant={'BaseM_B'} flex={1} textAlign={'left'} color={'typography.secondary'}>
                {
                  i18n.t('shop-myOrder-total') // TOTAL
                }
              </Typography>
              <Typography variant={'BaseM_B'} color={'primary'} flex={1} textAlign={'right'}>
                {order.transaction.amount.toLocaleString()} {currency}
              </Typography>
              <Divider type={'box'} color={'fill'}/>
            </Container>
          </Area>
          {
            order.transaction.status === 'COMPLETED' &&
            (<>
                <Divider type={'box'} color={'fill'}/>

                <Area
                  title={(
                    <Stack row alignItems={'center'} justifyContent={'space-between'}>
                      <Typography variant={'BaseM_B'}>{
                        i18n.t('shop-myOrder-paymentAmount') // Payment Amount
                      }</Typography>
                    </Stack>
                  )}
                  containerProps={{px: '20px', pb: '20px'}}
                >
                  <ValueList
                    rightCellAlign={'right'}
                    pairs={[
                      {
                        key: 'paymentMethod',
                        pair: [
                          i18n.t('shop-myOrder-paymentMethod') // Payment Method
                          ,
                          <Stack column>
                            <Typography variant={'BaseS_B'} whiteSpace={'nowrap'}>
                              {`${order.transaction.paymentMethod.split('_').join(' ')}`}
                            </Typography>
                            <Typography
                              variant={'Caption'}
                              color={'typography.tertiary'}
                            >{
                              i18n.t('shop-myOrder-confirmed') // Confirmed
                            }
                            </Typography>
                          </Stack>,
                        ],
                      },
                      {
                        key: 'totalAmount',
                        pair: [
                          i18n.t('shop-myOrder-totalAmount') // Total Amount
                          ,
                          <Stack column>
                            <Typography variant={'BaseS_B'} whiteSpace={'nowrap'}>
                              {`${order.transaction.amount.toLocaleString()} ${currency}`}
                            </Typography>
                            <Typography
                              variant={'Caption'}
                              color={'typography.tertiary'}
                            >
                              {`${dayjs(order.transaction.transactionEndedAt).format('YY.MM.DD (ddd) HH:mm:ss ')} (KST)`}
                            </Typography>
                          </Stack>,
                        ],
                      },
                    ]}
                  />
                </Area>
              </>
            )
          }
          <Divider type={'box'} color={'fill'}/>

          <Container>
            <List component={'ul'}>
              {
                orderIsCancelable &&
                  <ListItem
                      type={'headline'}
                      headline={
                        i18n.t('shop-myOrder-cancelOrder') // Cancel Order
                      }
                      headlineProps={{color: 'error.main'}}
                      description={
                        i18n.t('shop-myOrder-cannotCancelInTransit') // You can't cancel the order once it's in transit
                      }
                      trail={<Icon size={'16px'} color={'typography.tertiary'}>chevron_right</Icon>}
                      onClick={handleToggleCancel}
                  />
              }
              <ListItem
                type={'headline'}
                headline={
                  i18n.t('shop-myOrder-orderInquiryOrRefund') // Order inquiry or Refund request
                }
                // headlineProps={{ color: 'error.main' }}
                trail={<Icon size={'16px'} color={'typography.tertiary'}>chevron_right</Icon>}
                onClick={handleToggleCS}
              /> <ListItem
              type={'headline'}
              headline={
                i18n.t('shop-myOrder-agreements') // Agreements
              }
              // headlineProps={{ color: 'error.main' }}
              trail={<Icon size={'16px'} color={'typography.tertiary'}>chevron_right</Icon>}
              onClick={handleToGoAgreements}
            />
            </List>
          </Container>

          <Divider type={'box'} color={'fill'}/>

          <Container
            py={'20px'}
            px={'20px'}
          >
            <Typography variant={'caption'} color={'typography.tertiary'}>
              {
                i18n.t('shop-myOrder-minorAgreementCancel') // A contract concluded by a minor can be canceled by the minor or their legal representative, and if the legal representative does not consent, the contract can be canceled.
              }
            </Typography>
          </Container>

        </Area>

      </Page>
      <CSPopup open={openCS} onClose={handleToggleCS} email={'support@kup.travel'}/>
      <CancelPopover open={openCancel} onClose={handleToggleCancel} onCancel={handleCancel}/>
      <KupNotificationPermissionDialog timeout={0}/>
    </>
  );
}
