import {useLocation, useNavigate} from 'react-router-dom';
import useKupStore from '~kup/store';
import Container from '~/components/Container.tsx';
import Page from '~/components/Page.tsx';
import TextField from '~/components/TextField.tsx';
import Button from '~/components/Button.tsx';
import {useState} from 'react';
import List from '~/components/List.tsx';
import ListItem from '~/components/ListItem.tsx';
import {IIssuedCoupon} from '~kup/models/Coupon.ts';
import Divider from "~/components/Divider.tsx";
import Spotlight from "~/components/Spotlight.tsx";
import Icon from "~/components/Icon";
import {useTranslation} from "react-i18next";

export default function ShopCouponPage() {
  const {setAppliedCoupon} = useKupStore((state) => ({
    setAppliedCoupon: state.setAppliedCoupon,
  }));
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {usableCoupons} = location.state;
  const [newCouponCode, setNewCouponCode] = useState<string | undefined>(undefined);

  return (
    <Page
      type={'page'}
      name={
        i18n.t('shop-coupon-coupon') // Coupon
      }
    >
      <Container
      >
        <Container
          pt={'8px'}
          pb={'16px'}
          px={'20px'}
          stackProps={{
            flexDirection: 'row',
            gap: '8px',
            justifyContent: 'stretch'
          }}
        >
          <Container>
            <TextField
              fullWidth
              placeholder={'Input Coupon Code'}
              value={newCouponCode}
              onChange={(e) => setNewCouponCode(e.target.value)}
            />
          </Container>
          <Button size="small">Apply</Button>
        </Container>
        <Divider type={'line'}/>
        <Container
          py={'8px'}
        >
          {
            usableCoupons.length > 0 && (
              <List component={'ol'}>
                {
                  usableCoupons.map((issuedCoupon: IIssuedCoupon) => {
                    const handleUseCoupon = () => {
                      setAppliedCoupon(issuedCoupon);
                      navigate(-1);
                    };
                    return (
                      <>
                        <ListItem
                          key={issuedCoupon.id}
                          type={'headline'}
                          headline={issuedCoupon.coupon.name}
                          subHeadline={issuedCoupon.isAvailable ? `Discount: ${issuedCoupon.applicableDiscountAmount?.toLocaleString()} KRW` : ''}
                          description={issuedCoupon.coupon.description}
                          caution={issuedCoupon.unavailableReason ?? ''}
                          trail={
                            issuedCoupon.isAvailable &&
                              <Button
                                  size="small"
                                  color="fill"
                                  onClick={handleUseCoupon}
                              >Use It</Button>
                          }
                        />
                      </>
                    );
                  })
                }
              </List>
            )
          }
          {
            (!usableCoupons || usableCoupons.length === 0) && (
              <Container
                px={'20px'}
              >
                <Spotlight
                  textAlign={'center'}
                  lead={<Icon size={'48px'} color={'typography.primary'}>upcoming</Icon>}
                  headline={
                    i18n.t('shop-coupon-noAvailableCoupons') // No available coupons
                  }
                  description={
                    i18n.t('shop-coupon-noAvailableCouponsDetailed') // No available coupons. Please enter your coupon code or check the event page.
                  }
                  descriptionProps={{
                    whiteSpace: 'pre-wrap',
                  }}
                />
              </Container>
            )
          }

        </Container>

      </Container>
    </Page>
  );
}
