import {Slice} from "~common/store";
import Auth from "~kup/models/Auth.ts";

export interface AuthSlice {
  auth: Auth | null;
  setAuth: (auth: Auth) => void;
  setAuthLocale: (locale: string) => void;
}

export interface PersistedAuthSlice {
  auth: Auth | null;
}

export const persistAuthSlice: (state: AuthSlice) => PersistedAuthSlice = (state) => ({
  auth: state.auth,
});

export const createAuthSlice: Slice<AuthSlice> = (set, getState) => ({
  auth: null,
  setAuth: (auth) => set(() => ({auth})),
  setAuthLocale: (locale: string) => {
    const authObj = getState().auth;
    if (!authObj) return;
    const auth = authObj instanceof Auth ? authObj : new Auth(authObj);
    if (auth) {
      const localeChangedAuth = auth.changeLocale(locale);
      console.log(localeChangedAuth);
      set(() => ({auth: localeChangedAuth}))
    }
  }
});
