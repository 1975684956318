import {ReactNode} from "react";
import {default as MuiTabs, TabsProps as MuiTabsProps} from '@mui/material/Tabs';
import {default as MuiTab} from '@mui/material/Tab';


export type Tab = {
  label: ReactNode,
  value: string | number,
};
export type TabsProps = MuiTabsProps & {
  tabs: Tab[],
  scrollable?: boolean,
  type?:'normal'|'toggle'
}

export default function Tabs(props: TabsProps) {
  const {tabs, scrollable = false, ...restProps} = props;
  return (
    <MuiTabs
      {...restProps}
      variant={scrollable ? 'scrollable' : 'fullWidth'}
    >
      {
        tabs.map((t) => <MuiTab
          key={t.value}
          label={t.label}
          value={t.value}/>)
      }
    </MuiTabs>
  )
}
