import {useTranslation} from "react-i18next";
import Page from "~/components/Page.tsx";
import useKintStore from "~kint/store";
import Container from "~/components/Container.tsx";
import Divider from "~/components/Divider.tsx";
import Headline from "~/components/Headline.tsx";
import Area from "~/components/Area.tsx";
import ValueList from "~/components/ValueList.tsx";
import Button from "~/components/Button.tsx";
import {Navigate, useLocation, useNavigate} from "react-router-dom";

export default function ReservationConfirmPage() {
  const {setReservation} = useKintStore((state) => ({
    setReservation: state.setReservation,
  }));
  const {i18n} = useTranslation();
  const location = useLocation();
  const {reservation} = (location.state ?? {});
  const navigate = useNavigate();

  const handleClickYes = () => {
    setReservation(reservation);
    navigate('/reservation/name', {replace: true})
  }

  const handleClickNo = () => {
    setReservation(null);
    navigate('/reservation', {replace: true})
  }

  if (!reservation) return <Navigate to={'/reservation'} replace/>

  return (
    <Page
      type={'page'}
      fixedBottomPosition={'fixed'}
      fixedBottom={
        <Container
          bottom
          background={'paper'}
          pt={'8px'}
          px={'20px'}
          stackProps={{
            gap: '10px'
          }}
        >
          <Button variant={'contained'} color={'primary'} onClick={handleClickYes}>
            {i18n.t('reservation-confirm-yesCorrect')}
          </Button>
          <Button variant={'contained'} color={'fill'} onClick={handleClickNo}>
            {i18n.t('reservation-confirm-noIncorrect')}
          </Button>
        </Container>
      }
    >
      <Container
        py={'8px'}
        pb={'148px'}
      >
        <Container
          py={'16px'}
          px={'20px'}
        >
          <Headline
            size={'large'}
            headline={
              i18n.t('reservation-confirm-confirmReservationByClient', {clientName: reservation?.clientName})
              // Is this a reservation made by\n${reservation?.clientName}?
            }
            description={
              i18n.t('reservation-confirm-checkDetailsBelow')
            }
            headlineProps={{
              whiteSpace: 'pre-wrap'
            }}
          />
        </Container>
        <Divider type={'box'} color={'fill'}/>
        <Area
          py={'8px'}
          title={
            i18n.t('reservation-confirm-reservationInfo')
          }
          containerProps={{
            px: '20px'
          }}
        >
          <ValueList pairs={
            [
              {
                key: 'date', pair: [
                  i18n.t('reservation-confirm-date') // Date
                  , reservation?.date]
              },
              {
                key: 'tour', pair: [
                  i18n.t('reservation-confirm-tour') // Tour
                  , reservation?.product]
              },
              {
                key: 'travelers',
                pair: [
                  i18n.t('reservation-confirm-travelers') // Travelers
                  , [[
                    i18n.t('reservation-confirm-adult') // Adult
                    , reservation?.adult],
                    [
                      i18n.t('reservation-confirm-kid') // Kid
                      , reservation?.kid],
                    [
                      i18n.t('reservation-confirm-infant') // Infant
                      , reservation?.infant]]
                    .filter(([, count]) => !!count)
                    .map(([label, count]) => `${label} ${count}`)
                    .join(' / ')]
              },
              {
                key: 'Platform', pair: [
                  i18n.t('reservation-confirm-platform') // Platform
                  , reservation?.agency?.toUpperCase()]
              },
              {
                key: 'ID', pair: [
                  i18n.t('reservation-confirm-id') // ID
                  , reservation?.agencyCode?.toUpperCase()]
              },
            ]
          }/>
        </Area>
      </Container>

    </Page>
  )
}
