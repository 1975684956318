import {useNavigate} from "react-router-dom";
import recallCSChatting from "~kint/controllers/recallCSChatting.ts";
import {useSnackBar} from "~/contexts/SnackBarContext.tsx";
import useKintStore from "~kint/store";
import useLoading from "~/hooks/useLoading.tsx";
import Button, {ButtonProps} from "~/components/Button.tsx";
import {useTranslation} from "react-i18next";

export default function RecallCsChatting(props: {
  children?: string,
  color?: ButtonProps<'button'>['color'],
  service: 'KUP' | 'CS'
}) {
  const {i18n} = useTranslation();
  const {service = 'CS', children = i18n.t('component-recallCs-oneOnOneSupport'), color = 'primary'} = props;
  const {participant, reservation} = useKintStore((state) => ({
    participant: state.participant,
    reservation: state.reservation,
  }))
  const navigate = useNavigate();
  const {startLoading, stopLoading, loading} = useLoading();
  const {showSnackBar} = useSnackBar();

  function handleError() {
    showSnackBar({message: i18n.t('component-recallCs-contactEmail'), lift: true})
  }

  const handleCallCS = () => {
    if (!participant || !reservation) {
      handleError()
      return;
    }

    (async () => {
      startLoading();
      return await recallCSChatting(participant, reservation, service);
    })()
        .then((chatting) => {
          if (!chatting) {
            handleError();
            return;
          }
          navigate(`/message/chatting/${chatting!.id}`, {replace: false})
        })
        .catch(() => {
          handleError();
        })
        .finally(stopLoading)
  }


  return (
      <>
        <Button fullWidth onClick={handleCallCS} loading={loading} color={color} size={'medium'}>
          {children}
        </Button>
      </>
  )
}
