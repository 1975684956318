import {useEffect, useRef} from "react";

export default function useScrollIn(onIn?: () => void, onOut?: () => void, threshold?: number) {
  const observerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Check if IntersectionObserver is supported
    const isIntersectionObserverAvailable = 'IntersectionObserver' in window;

    if (isIntersectionObserverAvailable) {
      if (!onIn) return;
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            onIn();
          } else {
            onOut?.();
          }
        },
        { threshold: threshold ?? 0.75 }
      );

      if (observerRef.current) {
        observer.observe(observerRef.current);
      }

      return () => {
        if (observerRef.current) {
          observer.unobserve(observerRef.current);
        }
      };
    } else {
      // Fallback for browsers that do not support IntersectionObserver
      const handleScroll = () => {
        if (!observerRef.current) return;
        const rect = observerRef.current.getBoundingClientRect();
        const isVisible =
          rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);

        if (isVisible) {
          onIn?.();
        } else {
          onOut?.();
        }
      };

      // Attach the scroll event listener
      window.addEventListener('scroll', handleScroll);

      // Initial check
      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [onIn, onOut, threshold]);

  return observerRef;
}
