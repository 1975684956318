import {SystemStyleObject, Theme} from "@mui/system";
import {styled} from "@mui/material";
import {default as MuiBox, BoxProps as MuiBoxProps} from "@mui/material/Box";
import {
  AllPredefinedColors,
  TypographyColorTrails,
  isColorName,
  isSystemColor,
  isTypographyColors,
  isFill,
  isInherit
} from "~/components/commons/ColorNames.ts";
import Kup from "~/components/Logo/Kup.tsx";
import Discovery from "~/components/Logo/Discovery.tsx";
import Tour from '~/components/Logo/Tour';
import Chat from '~/components/Logo/Chat';
import Delivery from '~/components/Logo/Delivery.tsx';
import Shop from "~/components/Logo/Shop.tsx";

type Color = AllPredefinedColors

export type LogoProps = Omit<MuiBoxProps<'span'>, 'sx' | 'children'> & {
  height?: string,
  width?: string,
  color?: Color,
  children?: string,
  sx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>),
}

const SvgContainer = styled(MuiBox)({
  display: 'inline-block',
  flexGrow:0,
  lineHeight: 0,
})

function retrieveLogo(name: string, height?:string, width?:string) {
  switch (name.toLowerCase()) {
    case 'delivery':
      return <Delivery height={height} width={width}/>
    case 'shop':
      return <Shop height={height} width={width}/>
    case 'chat':
      return <Chat height={height} width={width}/>
    case 'tour':
      return <Tour height={height} width={width}/>
    case 'discovery':
      return <Discovery height={height} width={width}/>
    case 'kup':
    default:
      return <Kup height={height} width={width}/>
  }
}


export default function Logo(props: LogoProps) {
  const {height = '1em', width, color = 'inherit', children = 'kup', sx} = props;
  return (
      <SvgContainer
          {...props}
          component={'span'}
          sx={(theme) => {

            const checkColorValue = (color: Color) => {
              const tailingColor = color.split('.').at(-1) as TypographyColorTrails;
              return isTypographyColors(color) && tailingColor ? theme.palette.typography[tailingColor] : isFill(color) ? theme.palette.fill.primary : color
            };

            const checkColorPalette = (color: Color) => isColorName(color) ? theme.palette[color] : isSystemColor(color) ? theme.palette.system[color] : null;
            const colorVal = isInherit(color) ? 'inherit' : checkColorPalette(color)?.main ?? checkColorValue(color);

            const predefinedSX: SystemStyleObject<Theme> =  typeof sx === 'function' ? sx(theme) : (sx ?? {});
            return ({
              ...predefinedSX,
              height: height,
              color: colorVal
            })
          }}
      >
        {retrieveLogo(children, height, width)}
      </SvgContainer>)
}
