import {useEffect, useState} from "react";
import {DiscoveryPlace} from "~kup/models/Discovery.ts";
import {getPlace} from "~kup/controllers/discovery.ts";

export default function usePlace(placeId:string){
  const [place, setPlace] = useState<DiscoveryPlace|null>(null);
  useEffect(()=>{
    if(placeId)
      getPlace(placeId).then((place)=>setPlace(place));
  }, [placeId])
  return place
}