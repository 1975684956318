import {useState} from "react";
import Page from '~/components/Page';
import Container from '~/components/Container';
import Typography from '~/components/Typography';
import {useTranslation} from "react-i18next";


export default function OrderAgreements() {
  const {i18n} = useTranslation();
  const [lang, setLang] = useState<string>(i18n.language);
  const transitToEn = () => {
    setLang('en');
  }
  if (lang.toLowerCase() === 'zht') return <OrderAgreementsZht transitToEn={transitToEn}/>
  if (lang.toLowerCase() === 'zh') return <OrderAgreementsZh transitToEn={transitToEn}/>
  return <OrderAgreementsEn/>
}

function OrderAgreementsEn() {
  return (
    <Page
      type={'modal'}
      name={'Order Agreements'}
    >
      <Container
        pb={'20px'}
        px={'20px'}
      >
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            1. Introduction
          </Typography>
          By placing an order with K Tour Story Co., Ltd., you agree to the following terms and conditions:
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            2. Personal Information Collection and Use
          </Typography>
          You consent to the collection and use of your personal information, including your name, contact details,
          shipping address, and payment information, for the purposes of processing your order, delivering products,
          and providing customer service. Your personal information will be stored on Google Cloud servers located in
          Korea and Singapore and may be shared with third parties such as courier companies and payment processors
          solely for these purposes.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            3. Refund and Cancellation Policy
          </Typography>
          <ul>
            <li>
              Orders can be canceled before the product enters the preparation phase for delivery. Once the product is
              being prepared, cancellations are no longer possible, and the refund process must be followed.
            </li>
            <li>
              Refunds are available within 14 days of receiving the product, provided the product is unused and in a
              resalable condition. Refunds due to product defects or damage during shipping are also available. However,
              refunds for change of mind are not permitted.
            </li>
            <li>
              Customers are responsible for the cost of return shipping, which is calculated at 10,000 KRW (5,000 KRW
              each
              way) for domestic returns. For international returns, customers must cover the full cost of international
              shipping.
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            4. Seller and K Tour Story Co., Ltd. Responsibilities
          </Typography>
          K Tour Story Co., Ltd. is responsible for selling and delivering products but does not manufacture the
          products.
          We
          provide product information as supplied by the manufacturer and include AI-translated content for your
          convenience. Please note that AI translations may contain errors, and it is your responsibility to verify
          critical information using the original language provided. K Tour Story Co., Ltd. is not liable for any issues
          arising from the products themselves.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            5. Delivery Obligations
          </Typography>
          K Tour Story Co., Ltd. directly handles the delivery of products. To ensure successful delivery, you must take
          necessary actions, such as being present at the hotel lobby if you are staying at a hotel or arranging for
          the product to be held at the hotel lobby. Once delivery is completed, it is your responsibility to promptly
          pick up and secure the delivered items.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            6. Minors' Purchases
          </Typography>
          Purchases made by minors require the consent of a parent or legal guardian. If a purchase is made without
          such consent, the contract may be canceled at the request of the parent or legal guardian.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            7. Electronic Document Consent
          </Typography>
          Order-related information such as order confirmations and receipts will be provided in printed form with the
          delivery. Terms and conditions, as well as policy updates, will be posted on the kup.travel or kup.tours
          websites, and it is your responsibility to check these sites regularly for updates.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            8. Changes to Terms and Conditions
          </Typography>
          K Tour Story Co., Ltd. reserves the right to modify these terms and conditions at any time. Changes will be
          posted
          on the kup.travel or kup.tours websites. Continued use of our services after changes are posted constitutes
          your acceptance of the updated terms.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            9. Governing Law and Jurisdiction
          </Typography>
          These terms and conditions are governed by the laws of the Republic of Korea. Any disputes arising from your
          use of our services will be subject to the exclusive jurisdiction of the Seoul Central District Court.
        </Container>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            10. Responsibility for Providing Accurate Information
          </Typography>
          It is the customer's responsibility to provide accurate and complete information when placing an order.
          K Tour Story Co., Ltd. will not be held liable for any issues arising from incorrect or incomplete order
          information
          provided by the customer, including but not limited to wrong delivery addresses, incorrect contact details,
          or other errors that may affect the processing and delivery of the order. Any additional costs incurred due
          to such errors will be the customer's responsibility.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            11. Customer Inquiries
          </Typography>
          For inquiries regarding refunds or service usage, please contact us at support@kup.travel. Our customer
          support team is available to assist you with any issues related to your order or service experience.
        </Container>
      </Container>
    </Page>
  )
}

function OrderAgreementsZh({transitToEn}: { transitToEn: () => void }) {
  return (
    <Page
      type={'modal'}
      name={'订单协议'}
    >
      <Container
        pb={'20px'}
        px={'20px'}
      >
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            1. 简介
          </Typography>
          本内容为 AI 翻译，详细内容请参考<span onClick={transitToEn}
                                               style={{textDecoration: 'underline', cursor:'pointer'}}><b>英文</b></span>版。
          通过与 K Tour Story Co., Ltd. 下订单，您同意以下条款和条件：
        </Container>


        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            2. 个人信息的收集和使用
          </Typography>
          本内容为 AI 翻译，详细内容请参考英文版。
          您同意我们收集和使用您的个人信息，包括您的姓名、联系方式、送货地址和支付信息，以处理您的订单、配送产品和提供客户服务。您的个人信息将存储在位于韩国和新加坡的
          Google Cloud 服务器上，并可能仅用于此目的共享给第三方，例如快递公司和支付处理器。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            3. 退款和取消政策
          </Typography>
          <ul>
            <li>
              订单可以在产品进入准备阶段之前取消。一旦产品进入准备阶段，无法再取消订单，必须遵循退款流程。
            </li>
            <li>
              收到产品后14天内可退款，前提是产品未使用且处于可转售状态。因产品缺陷或运输损坏导致的退款也可获得。然而，因个人原因的退款不予允许。
            </li>
            <li>
              客户需承担退货运费，国内退货费用为10,000韩元（每程5,000韩元）。对于国际退货，客户必须承担全额国际运费。
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            4. 卖方与 K Tour Story Co., Ltd. 的责任
          </Typography>
          K Tour Story Co., Ltd. 负责产品的销售和交付，但不生产产品。我们提供由制造商提供的产品信息，并包括为您方便使用的
          AI 翻译内容。请注意，AI 翻译可能包含错误，您有责任使用原始语言验证关键信息。K Tour Story Co., Ltd.
          对产品本身引发的任何问题不承担责任。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            5. 交付义务
          </Typography>
          K Tour Story Co., Ltd.
          直接负责产品交付。为了确保成功交付，您需要采取必要的措施，例如，如果您住在酒店，请在酒店大堂接收货物，或安排产品放置在酒店大堂。一旦交付完成，您有责任及时领取并妥善保管已交付的物品。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            6. 未成年人购买
          </Typography>
          未成年人的购买需经父母或法定监护人同意。如果在未经同意的情况下进行购买，父母或法定监护人可以要求取消合同。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            7. 电子文件同意
          </Typography>
          与订单相关的信息如订单确认和收据将随交付以打印形式提供。条款和政策更新将发布在 kup.travel 或 kup.tours
          网站上，您有责任定期查看这些网站以获取更新。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            8. 条款和条件的更改
          </Typography>
          K Tour Story Co., Ltd. 保留随时修改这些条款和条件的权利。更改将在 kup.travel 或 kup.tours
          网站上发布。在更改发布后继续使用我们的服务即表示您接受更新后的条款。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            9. 适用法律和管辖权
          </Typography>
          本条款和条件受大韩民国法律管辖。因您使用我们服务而引起的任何争议将由首尔中央地方法院专属管辖。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            10. 提供准确信息的责任
          </Typography>
          客户有责任在下订单时提供准确完整的信息。K Tour Story Co., Ltd.
          不对因客户提供错误或不完整的订单信息（包括但不限于错误的送货地址、不正确的联系信息或其他可能影响订单处理和交付的错误）而产生的任何问题负责。因这些错误而产生的任何额外费用由客户承担。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            11. 客户咨询
          </Typography>
          对于有关退款或服务使用的咨询，请联系 support@kup.travel。我们的客户支持团队将协助您解决任何与订单或服务体验相关的问题。
        </Container>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            11. 客户咨询
          </Typography>
          对于有关退款或服务使用的咨询，请联系 support@kup.travel。我们的客户支持团队将协助您解决任何与订单或服务体验相关的问题。
        </Container>
      </Container>
    </Page>
  )
}

function OrderAgreementsZht({transitToEn}: { transitToEn: () => void }) {
  return (<Page
      type={'modal'}
      name={'訂單協議'}
    >
      <Container
        pb={'20px'}
        px={'20px'}
      >
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            1. 簡介
          </Typography>
          本內容為 AI 翻譯，詳細內容請參考英文版。
          通過與 K Tour Story Co., Ltd. 下訂單，您同意以下條款和條件：
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            2. 個人信息的收集和使用
          </Typography>
          本內容為 AI 翻譯，詳細內容請參考<span onClick={transitToEn}
                                               style={{textDecoration: 'underline', cursor:'pointer'}}><b>英文</b></span>版。
          您同意我們收集和使用您的個人信息，包括您的姓名、聯繫方式、送貨地址和支付信息，以處理您的訂單、配送產品和提供客戶服務。您的個人信息將存儲在位於韓國和新加坡的
          Google Cloud 伺服器上，並可能僅用於此目的共享給第三方，例如快遞公司和支付處理商。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            3. 退款和取消政策
          </Typography>
          <ul>
            <li>
              訂單可以在產品進入準備階段之前取消。一旦產品進入準備階段，無法再取消訂單，必須遵循退款流程。
            </li>
            <li>
              收到產品後14天內可退款，前提是產品未使用且處於可轉售狀態。因產品缺陷或運輸損壞導致的退款也可獲得。然而，因個人原因的退款不予允許。
            </li>
            <li>
              客戶需承擔退貨運費，國內退貨費用為10,000韓元（每程5,000韓元）。對於國際退貨，客戶必須承擔全額國際運費。
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            4. 賣方與 K Tour Story Co., Ltd. 的責任
          </Typography>
          K Tour Story Co., Ltd. 負責產品的銷售和交付，但不生產產品。我們提供由製造商提供的產品信息，並包括為您方便使用的
          AI 翻譯內容。請注意，AI 翻譯可能包含錯誤，您有責任使用原始語言驗證關鍵信息。K Tour Story Co., Ltd.
          對產品本身引發的任何問題不承擔責任。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            5. 交付義務
          </Typography>
          K Tour Story Co., Ltd.
          直接負責產品交付。為了確保成功交付，您需要採取必要的措施，例如，如果您住在酒店，請在酒店大堂接收貨物，或安排產品放置在酒店大堂。一旦交付完成，您有責任及時領取並妥善保管已交付的物品。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            6. 未成年人購買
          </Typography>
          未成年人的購買需經父母或法定監護人同意。如果在未經同意的情況下進行購買，父母或法定監護人可以要求取消合同。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            7. 電子文件同意
          </Typography>
          與訂單相關的信息如訂單確認和收據將隨交付以打印形式提供。條款和政策更新將發布在 kup.travel 或 kup.tours
          網站上，您有責任定期查看這些網站以獲取更新。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            8. 條款和條件的更改
          </Typography>
          K Tour Story Co., Ltd. 保留隨時修改這些條款和條件的權利。更改將在 kup.travel 或 kup.tours
          網站上發布。在更改發布後繼續使用我們的服務即表示您接受更新後的條款。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            9. 適用法律和管轄權
          </Typography>
          本條款和條件受大韓民國法律管轄。因您使用我們服務而引起的任何爭議將由首爾中央地方法院專屬管轄。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            10. 提供準確信息的責任
          </Typography>
          客戶有責任在下訂單時提供準確完整的信息。K Tour Story Co., Ltd.
          不對因客戶提供錯誤或不完整的訂單信息（包括但不限於錯誤的送貨地址、不正確的聯繫信息或其他可能影響訂單處理和交付的錯誤）而產生的任何問題負責。因這些錯誤而產生的任何額外費用由客戶承擔。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            11. 客戶諮詢
          </Typography>
          對於有關退款或服務使用的諮詢，請聯繫 support@kup.travel。 我們的客戶支持團隊將協助您解決任何與訂單或服務體驗相關的問題。
        </Container>
      </Container>
    </Page>
  )
}
